export enum ThemeType {
    Light,
    Dark,
}

export enum AppTheme {
    DeviceTheme, // none/0 => device preferred theme
    LightTheme,
    DarkTheme,
}

export enum Instrument {
    ElectricGuitar = "ELECTRIC_GUITAR",
    AcousticGuitar = "ACOUSTIC_GUITAR",
    Bass = "BASS",
}

export enum Difficulty {
    Beginner = "BEGINNER",
    Intermediate = "INTERMEDIATE",
    Difficult = "DIFFICULT",
    Impossible = "IMPOSSIBLE",
}

export enum Mastery {
    Basic = "BASIC",
    Comfortable = "COMFORTABLE",
    Experient = "EXPERIENT",
}

export enum UserPrivacy {
    Public = "PUBLIC",
    Private = "PRIVATE",
}

export enum PlaylistPrivacy {
    Public = "PUBLIC",
    Private = "PRIVATE",
}
