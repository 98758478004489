import { ApiResponse } from "./dto"

export function fetchApiGET(url: string, credentials?: string): Promise<ApiResponse> {
    const options = { headers: getHeaders(false, credentials) }
    return fetchAPI(url, options)
}

export function fetchApiPOST<T>(url: string, body?: T, credentials?: string): Promise<ApiResponse> {
    const options = {
        method: "POST",
        headers: getHeaders(true, credentials),
        body: JSON.stringify(body),
    }
    return fetchAPI(url, options)
}

export function fetchApiPATCH<T>(url: string, body?: T, credentials?: string): Promise<ApiResponse> {
    const options = {
        method: "PATCH",
        headers: getHeaders(true, credentials),
        body: JSON.stringify(body),
    }
    return fetchAPI(url, options)
}

export function fetchApiDelete(url: string, credentials: string): Promise<ApiResponse> {
    const options = {
        method: "DELETE",
        headers: getHeaders(true, credentials),
    }
    return fetchAPI(url, options)
}

export async function fetchAPI(url: string, options?: RequestInit): Promise<ApiResponse> {
    console.log("fetching: " + url)
    return fetch(url, options).then(handleResponse).catch(handleError)
}

async function handleResponse(response: Response): Promise<ApiResponse> {
    if (!response.ok) {
        return response.text().then((text) => {
            try {
                const json = JSON.parse(text)

                if (json.type && json.title && json.detail && json.status) {
                    console.log("Api error response: " + json.type)
                    return Promise.reject({
                        type: json.type,
                        title: json.type,
                        detail: json.detail,
                        status: json.status,
                    })
                } else {
                    console.log("Unknown error response: " + JSON.stringify(text, null, 4))
                    return Promise.reject(new Error("Unknown error: " + text))
                }
            } catch (err) {
                return Promise.reject(new Error(`Expected a json error response, got: ${text}`))
            }
        })
    }
    const { status, statusText, type } = response

    return response.text().then((text) => {
        try {
            const jsonData = text ? JSON.parse(text) : null
            // alert("api response: " + JSON.stringify(jsonData, null, 4))
            return { statusCode: status, statusText, type, data: jsonData }
        } catch (err) {
            return Promise.reject(new Error(`Expected a json response, error message: ${err}`))
        }
    })
}

function handleError(error: any): Promise<never> {
    //console.log(JSON.stringify(error, null, 4))
    console.log(`error caught: name=${error.name}, message=${error.message}`)

    error.message = error.message === "Failed to fetch" ? "Could not reach Server" : "Unknown Error"
    return Promise.reject(error)
}

function getHeaders(body: boolean, credentials?: string): Headers {
    const headers: any = { Accept: "application/json" }

    if (body) {
        headers["Content-Type"] = "application/json"
    }
    if (credentials) {
        headers["Access-Control-Allow-Headers"] = "Authorization"
        headers["Authorization"] = `Bearer ${credentials}`
    }

    return new Headers(headers)
}
