import { debounce } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { PlaylistInfo } from "../common/dto"
import { PlaylistCard } from "./PlaylistCard"

export const PlaylistCardResponsiveRow: React.FC<{
    playlists: PlaylistInfo[]
    canEdit: boolean
    handleUpdate: (updated: PlaylistInfo) => void
    handleDelete: (playlistId: string) => void
}> = ({ playlists, canEdit, handleUpdate, handleDelete }) => {
    const targetRef = useRef<HTMLInputElement>(null)
    const [width, setWidth] = useState(0)

    const updateDimensions = debounce(() => {
        if (targetRef && targetRef.current) {
            setWidth(targetRef.current.offsetWidth)
        }
    }, 50)

    useEffect(() => {
        updateDimensions()
        window.addEventListener("resize", updateDimensions)
        return () => {
            window.removeEventListener("resize", updateDimensions)
        }
    }, [])

    const maxElements = width > 0 ? 1 + Math.floor((width - 180) / (180 + 24)) : 2

    return (
        <div style={{ width: "100%" }} ref={targetRef}>
            <PlaylistCardRow
                maxElements={maxElements}
                playlists={playlists}
                canEdit={canEdit}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export const PlaylistCardRow: React.FC<{
    maxElements: number
    playlists: PlaylistInfo[]
    canEdit: boolean
    handleUpdate: (updated: PlaylistInfo) => void
    handleDelete: (playlistId: string) => void
}> = ({ maxElements, playlists, canEdit, handleUpdate, handleDelete }) => {
    const elementNum = maxElements > playlists.length ? playlists.length : maxElements

    return (
        <div
            style={{
                display: "grid",
                gridAutoRows: 0,
                gridTemplateRows: "1fr",
                overflowY: "hidden",
                gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
                gridGap: "24px",
            }}
        >
            {playlists.slice(0, elementNum).map((playlist, index) => (
                <PlaylistCard
                    key={index}
                    playlist={playlist}
                    canEdit={canEdit}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />
            ))}
        </div>
    )
}
