import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import ReactPlayer from "react-player"
import { TabData } from "./dto"
import { TAB } from "./IndexedTab"
import { processTab } from "./utils"

export const TabScrollingExperimentPage: React.FC = () => {
    const [breakpoint, setBreakpoint] = React.useState<string | undefined>(undefined)

    const [tabData, setTabData] = React.useState<TabData | undefined>()
    if (!tabData) {
        setTabData(processTab(TAB))
    }
    const tabBreakpoints = tabData ? tabData.breakpoints : []
    const filtered: string[] = tabBreakpoints.flatMap((bp) => (bp.timestamp ? [bp.timestamp] : []))
    const breakpoints: string[] = ["start", ...filtered]

    const [withBreakpoints, setWithBreakpoints] = React.useState<boolean>(true)
    const handleWithBreakpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWithBreakpoints(event.target.checked)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timestamp = (event.target as HTMLInputElement).value
        setBreakpoint(timestamp)
    }

    function scrollToElement(timestamp: string) {
        const id = `tab-breakpoint-${timestamp}`
        const element = document.getElementById(id)
        if (element) {
            // Scroll the whole page to top THEN scroll the element 10/10
            element.scrollIntoView({ behavior: "smooth", inline: "nearest" })
        } else {
            alert(`not found with id [tab-breakpoint-${timestamp}]`)
        }
    }

    function handlePlayerProgress(playedSeconds: number) {
        setPlayerMessage(`Played seconds: ${playedSeconds}`)
        setTotalSeconds(playedSeconds)

        const timestamp = asTime(playedSeconds)
        if (breakpoints.includes(timestamp)) setBreakpoint(timestamp)
    }

    const [totalSeconds, setTotalSeconds] = React.useState<number | undefined>(undefined)
    const [playerMessage, setPlayerMessage] = React.useState("no message for now")

    useEffect(() => {
        if (breakpoint) {
            scrollToElement(breakpoint)
        }
    }, [breakpoint])

    return (
        <React.Fragment>
            <div style={{ padding: "16px", paddingTop: "8px" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "40%" }}>
                        <ReactPlayer
                            width="100%"
                            url={"https://www.youtube.com/watch?v=HKtsdZs9LJo"}
                            controls={true}
                            onStart={() => setPlayerMessage("Started")}
                            onPause={() => setPlayerMessage(`Paused`)}
                            onProgress={(state) => handlePlayerProgress(state.playedSeconds)}
                            onDuration={(duration) => setPlayerMessage(`Duration is ${duration}`)}
                        />
                        {playerMessage}

                        <div style={{ display: "flex" }}>
                            <h4>TotalSeconds: {totalSeconds ? totalSeconds : "Nothing yet"}</h4>
                            {!!totalSeconds && <h4 style={{ marginLeft: "16px" }}>As time: {asTime(totalSeconds)}</h4>}
                            <div />
                        </div>
                        <FormControlLabel
                            control={<Switch defaultChecked onChange={handleWithBreakpointsChange} />}
                            label="With breakpoints"
                        />
                        {/**<pre>{JSON.stringify(tabBreakpoints, null, 4)}</pre>**/}
                    </div>
                    <div style={{ width: "60%" }}>
                        <BreakpointChooser />
                        <Paper
                            style={{
                                height: "80vh",
                                width: "100%",
                                overflow: "scroll",
                                marginTop: "8px",
                                padding: "8px",
                            }}
                        >
                            {withBreakpoints ? <TabWithBreakpoints /> : <pre>{TAB}</pre>}
                        </Paper>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    function BreakpointChooser() {
        return (
            <React.Fragment>
                <Paper style={{ padding: "8px" }}>
                    <Grid container>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">breakpoint</FormLabel>
                                <RadioGroup name="breakpoint" value={breakpoint} onChange={handleChange} row>
                                    {breakpoints.map((breakpoint, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={breakpoint.toString()}
                                            control={<Radio />}
                                            label={breakpoint.toString()}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }

    function TabWithBreakpoints(): JSX.Element {
        if (!tabData) {
            alert("No tab data extracted, can't show breakpoints")
            return <React.Fragment />
        }

        return (
            <React.Fragment>
                <div id={"tab-breakpoint-start"}>
                    {tabData.details.map((line, i) => (
                        <RenderLine key={`tab-details-line-${i}`} line={line} />
                    ))}
                </div>
                {<div id="yolo">A div with something</div>}
                {tabData.breakpoints.map((bp, i) => (
                    <div key={`tab-breakpoint-${bp.timestamp}`} id={`tab-breakpoint-${bp.timestamp}`}>
                        {bp.lines.map((line, ii) => (
                            <RenderLine key={`tab-breakpoint-${bp.timestamp}-line-${ii}`} line={line} />
                        ))}
                    </div>
                ))}
            </React.Fragment>
        )
    }

    function asTime(totalSeconds: number) {
        const hours = Math.floor(totalSeconds / 3600)
        const remainingSeconds = totalSeconds % 3600
        const minutes = Math.floor(remainingSeconds / 60)
        const seconds = Math.floor(remainingSeconds % 60)

        return `${timePadding(minutes)}:${timePadding(seconds)}`
    }

    function timePadding(num: number): string {
        return num > 9 ? num.toString() : `0${num}`
    }
}

const RenderLine: React.FC<{ line: string }> = ({ line }) => {
    return line ? (
        <Typography
            variant="h6"
            style={{
                textAlign: "left",
                margin: "0px",
                whiteSpace: "pre",
            }}
        >
            {line}
        </Typography>
    ) : (
        <br />
    )
}
