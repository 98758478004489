import { Avatar, Button, Paper, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { getUserSession, setUserSession } from "../base/session"
import { useSnack } from "../base/Snackbar"
import { isEmpty } from "../base/utils"
import { SettingsSwitch } from "../common/components/SettingsSwitch"
import { DEFAULT_PROFILE_PIC_URL, DRAWER_SIZE_PX } from "../common/constants"
import { UpdateUserDto, UserDetails } from "../common/dto"
import { getUserDetails, updateClient } from "../integrations/tabuation/users"

export const UserSettingsPage: React.FC<RouteComponentProps<{ username?: string }>> = ({ match }) => {
    const username: string | undefined | null = match.params.username
        ? match.params.username
        : getUserSession()?.userInfo?.username

    const isOwnUser: boolean = username != undefined && username === getUserSession()?.userInfo?.username

    const [userDetails, setUserDetails] = useState<UserDetails | undefined>()

    const { snackError } = useSnack()

    useEffect(() => {
        if (username) {
            getUserDetails(username).then(setUserDetails).catch(handleGetUserDetailsError)
        } else {
            snackError("no username??")
        }
    }, [])

    const history = useHistory()
    return (
        <div style={{ paddingLeft: DRAWER_SIZE_PX }}>
            <div style={{ padding: "24px" }}>
                <div style={{ display: "flex" }}>
                    {userDetails && <UserHeader userDetails={userDetails} isOwnUser={isOwnUser} />}
                </div>
                <h2>Settings </h2>
                {userDetails && <UserSettings userDetails={userDetails} />}
                {/** <pre>{userDetails && JSON.stringify(userDetails, null, 4)}</pre> **/}
            </div>
        </div>
    )

    function handleGetUserDetailsError(err: any): void {
        const message = err.type && err.type.includes("UserNotFound") ? "No user with that username found" : err.message
        snackError(message)
    }

    function UserSettings(props: { userDetails: UserDetails }): JSX.Element {
        const userDetails = props.userDetails

        const [edit, setEdit] = useState(false)
        const [updateSettings, setUpdateSettings] = useState<UpdateUserDto>({})

        function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
            const value = event.target.value ? event.target.value : undefined // filter empty values
            // TODO check if new settings are different than original
            setUpdateSettings({ ...updateSettings, [event.target.name]: value })
        }

        const isProfilePublic: boolean =
            updateSettings.isProfilePublic !== undefined ? updateSettings.isProfilePublic : userDetails.isProfilePublic
        const isNewPlaylistPublic: boolean =
            updateSettings.isNewPlaylistPublic !== undefined
                ? updateSettings.isNewPlaylistPublic
                : userDetails.isNewPlaylistPublic

        return (
            <Paper
                component="form"
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    label="ID"
                    type="text"
                    disabled
                    defaultValue={userDetails?.id}
                    variant="standard"
                    style={{ width: "400px" }}
                />
                <TextField
                    label="Username"
                    type="text"
                    disabled={!edit}
                    name="username"
                    onChange={handleChange}
                    defaultValue={userDetails?.username}
                    variant="standard"
                    style={{ width: "400px" }}
                />
                <TextField
                    label="Name"
                    type="text"
                    disabled={!edit}
                    name="name"
                    onChange={handleChange}
                    defaultValue={userDetails?.name}
                    variant="standard"
                    style={{ width: "400px" }}
                />
                <TextField
                    label="Email"
                    type="email"
                    disabled={!edit}
                    name="email"
                    onChange={handleChange}
                    defaultValue={userDetails?.email}
                    variant="standard"
                    style={{ width: "400px" }}
                />
                <TextField
                    label="Picture URL"
                    type="text"
                    disabled={!edit}
                    name="pictureUrl"
                    onChange={handleChange}
                    defaultValue={userDetails?.pictureUrl}
                    variant="standard"
                    style={{ width: "400px" }}
                />

                <SettingsSwitch
                    name={"isProfilePublic"}
                    label={`Profile Privacy: ${isProfilePublic ? "Public" : "Private"}`}
                    disabled={!edit}
                    checked={isProfilePublic}
                    handleChange={(event, checked) =>
                        setUpdateSettings({ ...updateSettings, [event.target.name]: checked })
                    }
                />
                <SettingsSwitch
                    name={"isNewPlaylistPublic"}
                    label={`Playlist Default Privacy: ${isNewPlaylistPublic ? "Public" : "Private"}`}
                    disabled={!edit}
                    checked={isNewPlaylistPublic}
                    handleChange={(event, checked) =>
                        setUpdateSettings({ ...updateSettings, [event.target.name]: checked })
                    }
                />
                <div style={{ display: "flex", justifyContent: "center", width: "400px" }}>
                    {edit ? (
                        <Button
                            variant="contained"
                            style={{ width: "200px" }}
                            onClick={handleSaveChanges}
                            disabled={isEmpty(updateSettings)}
                        >
                            <Typography>Save Changes</Typography>
                        </Button>
                    ) : (
                        <Button variant="outlined" style={{ width: "200px" }} onClick={() => setEdit(true)}>
                            <Typography>Edit settings</Typography>
                        </Button>
                    )}
                </div>
                {/**<pre>{updateSettings && JSON.stringify(updateSettings, null, 4)}</pre>**/}
            </Paper>
        )

        function handleSaveChanges() {
            updateClient(userDetails.id, updateSettings)
                .then((updatedUserDetails) => {
                    // TODO update updatedUserDetails
                    if (updatedUserDetails.username != userDetails.username) {
                        const session = getUserSession()
                        if (session?.userInfo?.username) {
                            session.userInfo.username = updatedUserDetails.username
                            setUserSession(session)
                            history.push(`/users/${username}/settings`)
                        }
                    }
                })
                .catch((err) => snackError(`Unknown error happened: ${err}`))
            setEdit(false)
        }
    }
}

const UserHeader: React.FC<{ userDetails: UserDetails; isOwnUser: boolean }> = ({ userDetails, isOwnUser }) => {
    const { username, pictureUrl } = userDetails
    const avatar = pictureUrl ? pictureUrl : DEFAULT_PROFILE_PIC_URL

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar style={{ width: 50, height: 50, marginRight: "16px" }} alt={username} src={avatar} />
                <Typography color="textSecondary" gutterBottom style={{ fontSize: "28px", fontWeight: "bold" }}>
                    {username}
                </Typography>
            </div>
        </div>
    )
}
