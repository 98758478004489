import { getCurrentUserToken } from "../../base/session"
import { BASE_URL } from "../../common/constants"
import { Tab } from "./../../common/dto"
import { fetchApiDelete, fetchApiGET, fetchApiPATCH, fetchApiPOST } from "./apiFetcher"
import { ApiResponse, CreateTabDto } from "./dto"

export const getTrackTabs = async (trackId: string): Promise<Tab[]> => {
    const username = localStorage.getItem("username")
    if (!username) throw Error("No username provided")

    const credentials = localStorage.getItem("credentials")
    if (!credentials) throw Error("No user logged in")

    const url = `${BASE_URL}/tracks/${trackId}/tabs`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res) => {
            return res.data._embedded?.tabList ? res.data._embedded?.tabList : []
        })
        .catch((err: Error) => {
            throw Error(`An error happened while fetching the tracks's tabs: ${err.message}`)
        })
}

export const addTabToTrack = async (trackId: string, requestDto: CreateTabDto): Promise<Tab> => {
    const url = `${BASE_URL}/tracks/${trackId}/tabs`

    return getCurrentUserToken()
        .then((token) => fetchApiPOST(url, requestDto, token))
        .then((res: ApiResponse) => {
            console.debug(`create tab response: ${JSON.stringify(res, null, 4)}`)
            return res.data as Tab
        })
        .catch((err: Error) => {
            throw Error(`An error happened while adding a new tab to the track ${trackId}: ${err.message}`)
        })
}

export const updateTabText = async (tabId: string, newTabText: string): Promise<Tab> => {
    const url = `${BASE_URL}/tabs/${tabId}`

    return getCurrentUserToken()
        .then((token) => {
            if (token) return fetchApiPATCH(url, { tabText: newTabText }, token)
            else throw Error("No user logged in")
        })
        .then((res: ApiResponse) => {
            console.debug(`update tab text response: ${JSON.stringify(res, null, 4)}`)
            return res.data as Tab
        })
        .catch((err: Error) => {
            throw Error(`An error happened while updating tab text for tab ${tabId}: ${err.message}`)
        })
}

export const deleteTab = async (tabId: string): Promise<void> => {
    const url = `${BASE_URL}/tabs/${tabId}`

    return getCurrentUserToken()
        .then((token) => {
            if (token) fetchApiDelete(url, token)
            else throw Error("No user logged in")
        })
        .catch((err: Error) => {
            throw Error(`An error happened while updating tab text for tab ${tabId}: ${err.message}`)
        })
}
