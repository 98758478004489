import { YoutubePlaylist, YoutubePlaylistItemsResponse, YoutubeSearchResponse } from "./dto"

const MOCKED_PLAYLIST_ITEMS_DATA = {
    kind: "youtube#playlistItemListResponse",
    etag: "n0Q31XzMnRqDzXSqlXqMQ2yQ0yQ",
    nextPageToken: "CAUQAA",
    items: [
        {
            kind: "youtube#playlistItem",
            etag: "uxxVPBrOhOGaDXMOBYA0DKHP5jo",
            id: "T0xBSzV1eV9rQ0NEbWFvNzE2QWpsc1lpTnYtTV9HYVdDYzlrV08zeTQuRTZDMTA3NDMyN0MwRUU1Ng",
            snippet: {
                publishedAt: "2021-04-03T11:30:19Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "Turnin On The Screw",
                description:
                    "Provided to YouTube by Universal Music Group\n\nTurnin On The Screw · Queens Of The Stone Age\n\nEra Vulgaris\n\n℗ An Interscope Records Release; ℗ 2007 UMG Recordings, Inc.\n\nReleased on: 2007-01-01\n\nProducer: Chris Goss\nProducer: Joshua Homme\nStudio  Personnel, Recording  Engineer, Mixer: Alain Johannes\nStudio  Personnel, Asst.  Recording  Engineer: John Silas Cranfield\nStudio  Personnel, Assistant  Mixer: Justin Smith\nComposer  Lyricist: Joshua Homme\nComposer  Lyricist: Troy Van Leeuwen\nComposer  Lyricist: Joey Castillo\n\nAuto-generated by YouTube.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/vQN_DTqHNqo/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/vQN_DTqHNqo/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/vQN_DTqHNqo/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                    standard: {
                        url: "https://i.ytimg.com/vi/vQN_DTqHNqo/sddefault.jpg",
                        width: 640,
                        height: 480,
                    },
                    maxres: {
                        url: "https://i.ytimg.com/vi/vQN_DTqHNqo/maxresdefault.jpg",
                        width: 1280,
                        height: 720,
                    },
                },
                channelTitle: "YouTube",
                playlistId: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
                position: 0,
                resourceId: {
                    kind: "youtube#video",
                    videoId: "vQN_DTqHNqo",
                },
                videoOwnerChannelTitle: "Queens Of The Stone Age - Topic",
                videoOwnerChannelId: "UCcYgNw9ne8v1cxcAD_sIOWQ",
            },
            contentDetails: {
                videoId: "vQN_DTqHNqo",
                videoPublishedAt: "2018-09-15T09:42:35Z",
            },
        },
        {
            kind: "youtube#playlistItem",
            etag: "7g4fq4ERlCz8NI_FNcsL9wCMw_U",
            id: "T0xBSzV1eV9rQ0NEbWFvNzE2QWpsc1lpTnYtTV9HYVdDYzlrV08zeTQuOUYxRUQwNDgxMkYzQTA2Mg",
            snippet: {
                publishedAt: "2021-04-03T11:30:19Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "Sick, Sick, Sick",
                description:
                    "Provided to YouTube by Universal Music Group\n\nSick, Sick, Sick · Queens Of The Stone Age\n\nEra Vulgaris\n\n℗ An Interscope Records Release; ℗ 2007 UMG Recordings, Inc.\n\nReleased on: 2007-01-01\n\nProducer: Chris Goss\nProducer: Joshua Homme\nStudio  Personnel, Recording  Engineer, Mixer: Alain Johannes\nStudio  Personnel, Asst.  Recording  Engineer: John Silas Cranfield\nStudio  Personnel, Assistant  Mixer: Justin Smith\nComposer  Lyricist: Joshua Homme\nComposer  Lyricist: Troy Van Leeuwen\nComposer  Lyricist: Joey Castillo\nComposer  Lyricist: Chris Goss\n\nAuto-generated by YouTube.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/UK8zSQ0kpj4/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/UK8zSQ0kpj4/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/UK8zSQ0kpj4/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                    standard: {
                        url: "https://i.ytimg.com/vi/UK8zSQ0kpj4/sddefault.jpg",
                        width: 640,
                        height: 480,
                    },
                    maxres: {
                        url: "https://i.ytimg.com/vi/UK8zSQ0kpj4/maxresdefault.jpg",
                        width: 1280,
                        height: 720,
                    },
                },
                channelTitle: "YouTube",
                playlistId: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
                position: 1,
                resourceId: {
                    kind: "youtube#video",
                    videoId: "UK8zSQ0kpj4",
                },
                videoOwnerChannelTitle: "Queens Of The Stone Age - Topic",
                videoOwnerChannelId: "UCcYgNw9ne8v1cxcAD_sIOWQ",
            },
            contentDetails: {
                videoId: "UK8zSQ0kpj4",
                videoPublishedAt: "2018-09-15T09:40:29Z",
            },
        },
        {
            kind: "youtube#playlistItem",
            etag: "mhDFJrF_AL7qJretttex8cnHfvM",
            id: "T0xBSzV1eV9rQ0NEbWFvNzE2QWpsc1lpTnYtTV9HYVdDYzlrV08zeTQuNzRENjkzNTE2NzgwRkI2Mw",
            snippet: {
                publishedAt: "2021-04-03T11:30:19Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "I'm Designer",
                description:
                    "Provided to YouTube by Universal Music Group\n\nI'm Designer · Queens Of The Stone Age\n\nEra Vulgaris\n\n℗ An Interscope Records Release; ℗ 2007 UMG Recordings, Inc.\n\nReleased on: 2007-01-01\n\nProducer: Chris Goss\nProducer: Joshua Homme\nStudio  Personnel, Recording  Engineer, Mixer: Alain Johannes\nStudio  Personnel, Asst.  Recording  Engineer: John Silas Cranfield\nStudio  Personnel, Assistant  Mixer: Justin Smith\nComposer  Lyricist: Joshua Homme\nComposer  Lyricist: Troy Van Leeuwen\nComposer  Lyricist: Joey Castillo\n\nAuto-generated by YouTube.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/QEdqwQyzCoc/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/QEdqwQyzCoc/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/QEdqwQyzCoc/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                    standard: {
                        url: "https://i.ytimg.com/vi/QEdqwQyzCoc/sddefault.jpg",
                        width: 640,
                        height: 480,
                    },
                    maxres: {
                        url: "https://i.ytimg.com/vi/QEdqwQyzCoc/maxresdefault.jpg",
                        width: 1280,
                        height: 720,
                    },
                },
                channelTitle: "YouTube",
                playlistId: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
                position: 2,
                resourceId: {
                    kind: "youtube#video",
                    videoId: "QEdqwQyzCoc",
                },
                videoOwnerChannelTitle: "Queens Of The Stone Age - Topic",
                videoOwnerChannelId: "UCcYgNw9ne8v1cxcAD_sIOWQ",
            },
            contentDetails: {
                videoId: "QEdqwQyzCoc",
                videoPublishedAt: "2018-09-15T09:42:20Z",
            },
        },
        {
            kind: "youtube#playlistItem",
            etag: "iaaZ0Vt6lgw2NzT5TOzQtKe4MAI",
            id: "T0xBSzV1eV9rQ0NEbWFvNzE2QWpsc1lpTnYtTV9HYVdDYzlrV08zeTQuRDhEMDY5N0M4NzA1MDgwNg",
            snippet: {
                publishedAt: "2021-04-03T11:30:19Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "Into The Hollow",
                description:
                    "Provided to YouTube by Universal Music Group\n\nInto The Hollow · Queens Of The Stone Age\n\nEra Vulgaris\n\n℗ An Interscope Records Release; ℗ 2007 UMG Recordings, Inc.\n\nReleased on: 2007-01-01\n\nProducer: Chris Goss\nProducer: Joshua Homme\nStudio  Personnel, Recording  Engineer, Mixer: Alain Johannes\nStudio  Personnel, Asst.  Recording  Engineer: John Silas Cranfield\nStudio  Personnel, Assistant  Mixer: Justin Smith\nComposer  Lyricist: Joshua Homme\nComposer  Lyricist: Troy Van Leeuwen\nComposer  Lyricist: Joey Castillo\n\nAuto-generated by YouTube.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/yAmvNwM8780/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/yAmvNwM8780/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/yAmvNwM8780/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                    standard: {
                        url: "https://i.ytimg.com/vi/yAmvNwM8780/sddefault.jpg",
                        width: 640,
                        height: 480,
                    },
                    maxres: {
                        url: "https://i.ytimg.com/vi/yAmvNwM8780/maxresdefault.jpg",
                        width: 1280,
                        height: 720,
                    },
                },
                channelTitle: "YouTube",
                playlistId: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
                position: 3,
                resourceId: {
                    kind: "youtube#video",
                    videoId: "yAmvNwM8780",
                },
                videoOwnerChannelTitle: "Queens Of The Stone Age - Topic",
                videoOwnerChannelId: "UCcYgNw9ne8v1cxcAD_sIOWQ",
            },
            contentDetails: {
                videoId: "yAmvNwM8780",
                videoPublishedAt: "2018-09-15T09:44:33Z",
            },
        },
        {
            kind: "youtube#playlistItem",
            etag: "rv9KIX3bz8HUQ3mElsQhrGdfGKk",
            id: "T0xBSzV1eV9rQ0NEbWFvNzE2QWpsc1lpTnYtTV9HYVdDYzlrV08zeTQuNkQ2ODFGM0FFMzYyNEJEMA",
            snippet: {
                publishedAt: "2021-04-03T11:30:19Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "Misfit Love",
                description:
                    "Provided to YouTube by Universal Music Group\n\nMisfit Love · Queens Of The Stone Age\n\nEra Vulgaris\n\n℗ An Interscope Records Release; ℗ 2007 UMG Recordings, Inc.\n\nReleased on: 2007-01-01\n\nProducer: Chris Goss\nProducer: Joshua Homme\nStudio  Personnel, Recording  Engineer, Mixer: Alain Johannes\nStudio  Personnel, Asst.  Recording  Engineer: John Silas Cranfield\nStudio  Personnel, Assistant  Mixer: Justin Smith\nComposer  Lyricist: Joshua Homme\nComposer  Lyricist: Troy Van Leeuwen\nComposer  Lyricist: Joey Castillo\n\nAuto-generated by YouTube.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/DaTjTB3nQWM/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/DaTjTB3nQWM/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/DaTjTB3nQWM/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                    standard: {
                        url: "https://i.ytimg.com/vi/DaTjTB3nQWM/sddefault.jpg",
                        width: 640,
                        height: 480,
                    },
                    maxres: {
                        url: "https://i.ytimg.com/vi/DaTjTB3nQWM/maxresdefault.jpg",
                        width: 1280,
                        height: 720,
                    },
                },
                channelTitle: "YouTube",
                playlistId: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
                position: 4,
                resourceId: {
                    kind: "youtube#video",
                    videoId: "DaTjTB3nQWM",
                },
                videoOwnerChannelTitle: "Queens Of The Stone Age - Topic",
                videoOwnerChannelId: "UCcYgNw9ne8v1cxcAD_sIOWQ",
            },
            contentDetails: {
                videoId: "DaTjTB3nQWM",
                videoPublishedAt: "2018-09-15T09:41:55Z",
            },
        },
    ],
    pageInfo: {
        totalResults: 15,
        resultsPerPage: 5,
    },
}

const MOCKED_PLAYLIST_RESPONSE = {
    kind: "youtube#playlistListResponse",
    etag: "f9i_x5zC2NNhaABG7l_d1m70an8",
    pageInfo: {
        totalResults: 1,
        resultsPerPage: 5,
    },
    items: [
        {
            kind: "youtube#playlist",
            etag: "yBSZ2M7WJCbHLmJoJqhQ4W3oCTY",
            id: "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4",
            snippet: {
                publishedAt: "2019-03-07T17:43:42Z",
                channelId: "UCBR8-60-B28hp2BmDPdntcQ",
                title: "Era Vulgaris",
                description: "",
                thumbnails: {
                    medium: {
                        url:
                            "https://i9.ytimg.com/s_p/OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4/mqdefault.jpg?sqp=COyoxoMGir7X7AMGCM6wheQF&rs=AOn4CLARBxmcEjABEtykvr6ece5-3drQfQ&v=1551980622",
                        width: 180,
                        height: 180,
                    },
                    standard: {
                        url:
                            "https://i9.ytimg.com/s_p/OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4/sddefault.jpg?sqp=COyoxoMGir7X7AMGCM6wheQF&rs=AOn4CLBzRewyOau1e09D_9yjX1Tp3Vk_Fg&v=1551980622",
                        width: 640,
                        height: 640,
                    },
                    maxres: {
                        url:
                            "https://i9.ytimg.com/s_p/OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4/maxresdefault.jpg?sqp=COyoxoMGir7X7AMGCM6wheQF&rs=AOn4CLD2UFVuetAJERruaJFv8PQrE5YCxA&v=1551980622",
                        width: 1200,
                        height: 1200,
                    },
                },
                channelTitle: "YouTube",
                localized: {
                    title: "Era Vulgaris",
                    description: "",
                },
            },
        },
    ],
}

const _MOCKED_SEARCH_VIDEO_RESPONSE = {
    kind: "youtube#searchListResponse",
    etag: "3nk_P5MxhehtPHlReSn4-mr2hiQ",
    nextPageToken: "CBQQAA",
    regionCode: "PT",
    pageInfo: {
        totalResults: 57971,
        resultsPerPage: 20,
    },
    items: [
        {
            kind: "youtube#searchResult",
            etag: "7iqXjpQSFF91XH7t684f6gqdBi0",
            id: {
                kind: "youtube#video",
                videoId: "-emB4nYjTpc",
            },
            snippet: {
                publishedAt: "2019-08-23T18:58:47Z",
                channelId: "UCx4JBdCPMWdTStXFzIs9itQ",
                title: "Muse - Origin Of Symmetry (Japanese Edition) (Full Album)",
                description:
                    "Origin Of Symmetry · Muse Origin Of Symmetry New Born 0:00 Bliss 6:03 Space Dementia 10:15 Hyper Music 16:36 Plug In Baby 19:57 Citizen Erased 23:38 ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/-emB4nYjTpc/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/-emB4nYjTpc/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/-emB4nYjTpc/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "The Muse",
                liveBroadcastContent: "none",
                publishTime: "2019-08-23T18:58:47Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "QIeboYOdfXpX8h7ax1HSSOHKbPo",
            id: {
                kind: "youtube#video",
                videoId: "tazgBT1zafc",
            },
            snippet: {
                publishedAt: "2020-04-04T19:30:10Z",
                channelId: "UCx4JBdCPMWdTStXFzIs9itQ",
                title: "Muse - Origin Of Symmetry: Live (Special Edition)",
                description:
                    "Origin Of Symmetry: Live (Special Edition) New Born - Live 0:00 Bliss - Live 6:16 Space Dementia - Live 11:24 Hyper Music - Live 18:19 Plug In Baby - Live ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/tazgBT1zafc/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/tazgBT1zafc/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/tazgBT1zafc/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "The Muse",
                liveBroadcastContent: "none",
                publishTime: "2020-04-04T19:30:10Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "vmyWQAzZ738Ulqp3qgL5fBJ68Y8",
            id: {
                kind: "youtube#video",
                videoId: "qhduQhDqtb4",
            },
            snippet: {
                publishedAt: "2010-02-08T16:23:32Z",
                channelId: "UCGGhM6XCSJFQ6DTRffnKRIw",
                title: "Muse - New Born",
                description:
                    'Watch the music video for "New Born" now! Get Muse\'s album ORIGIN OF SYMMETRY here: http://smarturl.it/getmuseoriginofsymm http://muse.mu ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/qhduQhDqtb4/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/qhduQhDqtb4/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/qhduQhDqtb4/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Muse",
                liveBroadcastContent: "none",
                publishTime: "2010-02-08T16:23:32Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "u0XGcBcm-8IF9ygqZlp_1bgAlcY",
            id: {
                kind: "youtube#video",
                videoId: "mRKzmssY9pc",
            },
            snippet: {
                publishedAt: "2018-03-07T22:00:01Z",
                channelId: "UCVOjJR_2CwJpGZ5JJhkeFfQ",
                title: "Muse - Origin of Symmetry (2001) | Album Review",
                description:
                    '▻My (redemption) review of UK rockers Muse & their second album "Origin of Symmetry". "Origin of Symmetry" Maverick Records Release: 7/17/2001 Rating: ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/mRKzmssY9pc/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/mRKzmssY9pc/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/mRKzmssY9pc/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "ARTV",
                liveBroadcastContent: "none",
                publishTime: "2018-03-07T22:00:01Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "-iwj4H2EO3Dsr4JzPfqOQUQ0sNg",
            id: {
                kind: "youtube#video",
                videoId: "nO4aXkR0ZY4",
            },
            snippet: {
                publishedAt: "2019-12-03T10:00:08Z",
                channelId: "UCGGhM6XCSJFQ6DTRffnKRIw",
                title: "Origin of Muse: Origin of Symmetry Era [Out Now]",
                description:
                    "This deluxe set will chronicle the band from their early beginnings in Devon, their early demos, first EPs, debut album and breakthrough record Origin of ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/nO4aXkR0ZY4/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/nO4aXkR0ZY4/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/nO4aXkR0ZY4/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Muse",
                liveBroadcastContent: "none",
                publishTime: "2019-12-03T10:00:08Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "Fho2b_gJKLLsM3Fuqh9gI-q29oA",
            id: {
                kind: "youtube#video",
                videoId: "eMqsWc8muj8",
            },
            snippet: {
                publishedAt: "2010-02-08T16:15:41Z",
                channelId: "UCGGhM6XCSJFQ6DTRffnKRIw",
                title: "Muse - Bliss",
                description:
                    'Watch the music video for "Bliss" now! Get Muse\'s album ORIGIN OF SYMMETRY here: http://smarturl.it/getmuseoriginofsymm http://muse.mu ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/eMqsWc8muj8/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/eMqsWc8muj8/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/eMqsWc8muj8/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Muse",
                liveBroadcastContent: "none",
                publishTime: "2010-02-08T16:15:41Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "9mFwr7w1yUvdNMIVPsBqoB6l6qw",
            id: {
                kind: "youtube#video",
                videoId: "I2QN3DWLY80",
            },
            snippet: {
                publishedAt: "2020-05-05T20:00:10Z",
                channelId: "UCu8XvU8QkJ8zc0J15n7ZvHw",
                title: "Muse | Making of Origin of Symmetry | 2001",
                description:
                    "Making of Origin of Symmetry · Muse ℗ 2001 Muse Taken from Muse TV, released on the Origin of Symmetry CD.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/I2QN3DWLY80/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/I2QN3DWLY80/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/I2QN3DWLY80/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "BTec DomHoward",
                liveBroadcastContent: "none",
                publishTime: "2020-05-05T20:00:10Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "4UAGhE8sPPxOsdf0ycwIKhu_SYk",
            id: {
                kind: "youtube#video",
                videoId: "C0uFakDj5D8",
            },
            snippet: {
                publishedAt: "2011-05-23T22:49:41Z",
                channelId: "UCi9AK7VUedgcL2jjXKPLlFw",
                title: "Muse - New Born [HD]",
                description:
                    "The fantastic song New Born by Muse, from the album Origin of Symmetry. I claim ownership of nothing shown in this video.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/C0uFakDj5D8/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/C0uFakDj5D8/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/C0uFakDj5D8/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "MrMuseLyrics",
                liveBroadcastContent: "none",
                publishTime: "2011-05-23T22:49:41Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "-ivAq82-gX5yNFmvaCRgbXkmTKE",
            id: {
                kind: "youtube#video",
                videoId: "tjm3NyQ6DYw",
            },
            snippet: {
                publishedAt: "2010-02-08T16:13:32Z",
                channelId: "UCGGhM6XCSJFQ6DTRffnKRIw",
                title: "Muse - Hyper Music",
                description:
                    'Watch the music video for "Hyper Music" now! Get Muse\'s album ORIGIN OF SYMMETRY here: http://smarturl.it/getmuseoriginofsymm http://muse.mu ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/tjm3NyQ6DYw/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/tjm3NyQ6DYw/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/tjm3NyQ6DYw/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Muse",
                liveBroadcastContent: "none",
                publishTime: "2010-02-08T16:13:32Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "4kYq_Tsi_BkNt1vrXDMLFxjbEcQ",
            id: {
                kind: "youtube#video",
                videoId: "-bNnekk2_Yk",
            },
            snippet: {
                publishedAt: "2011-05-25T00:03:25Z",
                channelId: "UCi9AK7VUedgcL2jjXKPLlFw",
                title: "Muse - Space Dementia [HD]",
                description:
                    "The fantastic song Space Dementia by Muse, from the album Origin of Symmetry. I claim ownership of nothing shown in this video.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/-bNnekk2_Yk/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/-bNnekk2_Yk/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/-bNnekk2_Yk/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "MrMuseLyrics",
                liveBroadcastContent: "none",
                publishTime: "2011-05-25T00:03:25Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "6HyKknWE1X9ixKED3bkQlvRopIA",
            id: {
                kind: "youtube#video",
                videoId: "OSGm_KDHmcc",
            },
            snippet: {
                publishedAt: "2016-07-21T18:11:33Z",
                channelId: "UCF83-AAQJpF88auDbnzfVrQ",
                title: "Muse - Live Fort de Saint-Père 2001 [Route du Rock 2001 BROADCAST] (Saint-Malo, France HD)",
                description:
                    "This concert happened almost a month after Origin of Symmetry came out and the band is in top shape and crazier than ever! Hope you enjoy this show and as ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/OSGm_KDHmcc/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/OSGm_KDHmcc/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/OSGm_KDHmcc/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Dylan Navarro",
                liveBroadcastContent: "none",
                publishTime: "2016-07-21T18:11:33Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "-GfZDwYO9at5Xi_tZVxURBwtUPA",
            id: {
                kind: "youtube#video",
                videoId: "2eSP6djkHds",
            },
            snippet: {
                publishedAt: "2019-10-09T11:01:12Z",
                channelId: "UCr6GbPo2SMZgtBE_BNUuDSA",
                title: "Muse - Reading Festival - 28th August 2011 (HD)",
                description: "Muse headline Reading and celebrate 10 years of 'Origin Of Symmetry'.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/2eSP6djkHds/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/2eSP6djkHds/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/2eSP6djkHds/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Craig Fairlie",
                liveBroadcastContent: "none",
                publishTime: "2019-10-09T11:01:12Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "vABmSRaoH90nzDS6JPBoTpdiSWY",
            id: {
                kind: "youtube#video",
                videoId: "S8Hqutjp2dA",
            },
            snippet: {
                publishedAt: "2020-04-06T02:30:10Z",
                channelId: "UCx4JBdCPMWdTStXFzIs9itQ",
                title: "Muse - Origin Of Symmetry (Bleeb Version Unmastered)",
                description:
                    "Origin Of Symmetry (Bleeb Version) - Muse New Born 0:00 Bliss 6:05 Space Dementia 10:44 Hypermusic 16:47 Plug in Baby 20:15 Citizen Erased 23:56 Micro ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/S8Hqutjp2dA/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/S8Hqutjp2dA/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/S8Hqutjp2dA/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "The Muse",
                liveBroadcastContent: "none",
                publishTime: "2020-04-06T02:30:10Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "5cWzrfLZVKch4QqWde3ItVvtvCs",
            id: {
                kind: "youtube#video",
                videoId: "SjhsJoyY4Ko",
            },
            snippet: {
                publishedAt: "2019-06-25T21:53:44Z",
                channelId: "UCGnRtE2HO47UaK4X4WNTNMg",
                title: "Suggestion Session 58: Muse - Origin of Symmetry ALBUM REACTION",
                description:
                    "Emotion bleeds through in every song, accompanied by an impressive vocal showcase from Matt Bellamy. There's no denying that Origin exists in its own lane ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/SjhsJoyY4Ko/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/SjhsJoyY4Ko/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/SjhsJoyY4Ko/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "The Tune Up",
                liveBroadcastContent: "none",
                publishTime: "2019-06-25T21:53:44Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "gxHV5Mdn4KKGqIP6bvYcSQaHzD0",
            id: {
                kind: "youtube#video",
                videoId: "HXvrZr9Ix-M",
            },
            snippet: {
                publishedAt: "2020-09-25T21:15:43Z",
                channelId: "UCAVaWMoFZEWXGXuDxKaLNZA",
                title: "Muse - Origin of Symmetry REACTION/REVIEW (PART 1)",
                description:
                    "PART 2: Tmr :) Favorite Track So Far: Plug In Baby Least Favorite Track So Far: New Born (still, amazing track) Genres: Space Rock, Prog Rock, Alternative ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/HXvrZr9Ix-M/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/HXvrZr9Ix-M/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/HXvrZr9Ix-M/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "PASSTHEauxCORD",
                liveBroadcastContent: "none",
                publishTime: "2020-09-25T21:15:43Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "eK9P76LXdM2xMkKnDcgC0S6kyTE",
            id: {
                kind: "youtube#video",
                videoId: "ADEeud8LJ3I",
            },
            snippet: {
                publishedAt: "2019-01-19T09:16:05Z",
                channelId: "UClIVdptck7v2IVDn2p82aug",
                title: "Muse - ORIGIN OF SYMMETRY - CENTER CUT - Part 1",
                description:
                    'What you\'ll hear in this audio are songs Screenager, Darkshines, Feeling Good and Megalomania without the "center channel". Center channel is whatever is in ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/ADEeud8LJ3I/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/ADEeud8LJ3I/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/ADEeud8LJ3I/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "April Space",
                liveBroadcastContent: "none",
                publishTime: "2019-01-19T09:16:05Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "sjq7Cy-43Uj334qAegwfmCVrPmQ",
            id: {
                kind: "youtube#video",
                videoId: "qlw-OCDap4I",
            },
            snippet: {
                publishedAt: "2017-12-12T18:30:01Z",
                channelId: "UCV-P6xxPu7Y-C8H2SHtMO0g",
                title: "[ Muse ] Origin of Symmetry - Ukulele Medley",
                description:
                    "Muse and the Origin of Symmetry album arranged for the ukulele! ❇️ Guaranteed progress on the Ukulele, Guitar and Bass. ❇️ Guided lesson paths that WILL ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/qlw-OCDap4I/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/qlw-OCDap4I/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/qlw-OCDap4I/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "EatMyUke",
                liveBroadcastContent: "none",
                publishTime: "2017-12-12T18:30:01Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "h0_INnUxo3hVEXeHc1YMP6rF74w",
            id: {
                kind: "youtube#video",
                videoId: "dbB-mICjkQM",
            },
            snippet: {
                publishedAt: "2010-02-08T16:19:40Z",
                channelId: "UCGGhM6XCSJFQ6DTRffnKRIw",
                title: "Muse - Plug In Baby (Official Video)",
                description:
                    'Watch the official music video for "Plug In Baby" now! Get Muse\'s album ORIGIN OF SYMMETRY here: http://smarturl.it/getmuseoriginofsymm http://muse.mu ...',
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/dbB-mICjkQM/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/dbB-mICjkQM/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/dbB-mICjkQM/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Muse",
                liveBroadcastContent: "none",
                publishTime: "2010-02-08T16:19:40Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "lbfp2eRS5PgvgJGJo5wruGaNXUU",
            id: {
                kind: "youtube#video",
                videoId: "y31WAq4cENo",
            },
            snippet: {
                publishedAt: "2011-06-30T23:47:36Z",
                channelId: "UCi9AK7VUedgcL2jjXKPLlFw",
                title: "Muse - Micro Cuts [HD]",
                description:
                    "The fantastic song Micro Cuts by Muse, from the album Origin of Symmetry. I claim ownership of nothing shown in this video.",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/y31WAq4cENo/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/y31WAq4cENo/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/y31WAq4cENo/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "MrMuseLyrics",
                liveBroadcastContent: "none",
                publishTime: "2011-06-30T23:47:36Z",
            },
        },
        {
            kind: "youtube#searchResult",
            etag: "yT718ct4TrF2RUOkFCBYus8Anms",
            id: {
                kind: "youtube#video",
                videoId: "Tgkn-kqGY-Y",
            },
            snippet: {
                publishedAt: "2020-06-30T17:23:40Z",
                channelId: "UCDu5GCr1TAijytF_aXA4WiQ",
                title: "Muse - Rock Extraño... Pero Tradicional - Origin of Symmetry",
                description:
                    "Esta semana hablamos del Origin of Symmetry de Muse. Un disco que tardó 4 años en cruzar a America por no permitirle libertad creativa a una banda que ...",
                thumbnails: {
                    default: {
                        url: "https://i.ytimg.com/vi/Tgkn-kqGY-Y/default.jpg",
                        width: 120,
                        height: 90,
                    },
                    medium: {
                        url: "https://i.ytimg.com/vi/Tgkn-kqGY-Y/mqdefault.jpg",
                        width: 320,
                        height: 180,
                    },
                    high: {
                        url: "https://i.ytimg.com/vi/Tgkn-kqGY-Y/hqdefault.jpg",
                        width: 480,
                        height: 360,
                    },
                },
                channelTitle: "Pepis Music",
                liveBroadcastContent: "none",
                publishTime: "2020-06-30T17:23:40Z",
            },
        },
    ],
}

export const MOCKED_PLAYLIST_ITEMS_RESPONSE: YoutubePlaylistItemsResponse = MOCKED_PLAYLIST_ITEMS_DATA

export const MOCKED_PLAYLIST: YoutubePlaylist = MOCKED_PLAYLIST_RESPONSE.items[0]

export const MOCKED_SEARCH_VIDEO_RESPONSE: YoutubeSearchResponse = _MOCKED_SEARCH_VIDEO_RESPONSE
