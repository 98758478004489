import { Grid } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import { UserInfo } from "../common/dto"
import { UserInfoCard } from "./UserInfoCard"

export function UserCardGrid(props: { userList: UserInfo[] }): JSX.Element {
    const history = useHistory()
    return (
        <Grid container>
            <Grid container spacing={1} style={{ marginTop: "10px", marginBottom: "10px" }}>
                {props.userList.map((userInfo) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"card_" + userInfo.username}>
                        <UserInfoCard
                            userInfo={userInfo}
                            goToLink={(username: string) => history.push(`/users/${username}`)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
