import BackIcon from "@mui/icons-material/ArrowBackIos"
import CloseIcon from "@mui/icons-material/Close"
import { ButtonGroup, Grid, IconButton, Paper, styled, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom"
import { getSessionPlaylists, getUserSession } from "../../base/session"
import { useSnack } from "../../base/Snackbar"
import { DRAWER_SIZE } from "../../common/constants"
import { PlaylistInfo, Tab, TrackInfo } from "../../common/dto"
import { getPlaylist, getPlaylistTracks } from "../../integrations/tabuation/playlists"
import { deleteTab } from "../../integrations/tabuation/tabs"
import { getUserTracks } from "../../integrations/tabuation/tracks"
import { TabInterface } from "./Components/TabInterface"
import { Tracklist } from "./Components/Tracklist"

const StartIcon = styled(IconButton)(() => ({
    paddingTop: "0px",
    paddingBottom: "0px",
}))

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const PlaylistPlayer: React.FC<RouteComponentProps<{ playlistId?: string }>> = ({ match }) => {
    const username = getUserSession()?.userInfo?.username
    const playlistId: string | undefined = match.params.playlistId
    const query = useQuery()

    const [playlist, setPlaylist] = useState<PlaylistInfo | undefined>(undefined)
    const [tracks, setTracks] = useState<TrackInfo[]>([])

    const queryTrackIndex = query.get("track")
    const [trackIndex, setTrackIndex] = useState<number | undefined>(queryTrackIndex ? parseInt(queryTrackIndex) : 0)
    const track: TrackInfo | undefined = trackIndex !== undefined ? tracks[trackIndex] : undefined

    const [playerOpen, setPlayerOpen] = useState<boolean>(query.get("playerOpen") == "true")

    const [playlists, setPlaylists] = useState<PlaylistInfo[] | undefined>(undefined)

    const [timestamp, setTimestamp] = useState<string>("Start")

    useEffect(() => {
        if (playlistId) {
            fetchPlaylist(playlistId)
            fetchPlaylistTracks(playlistId)
        } else {
            username ? fetchUserTracks(username) : alert("No user logged in")
        }
    }, [playlistId])

    useEffect(() => {
        fetchLoggedInPlaylists()
    }, [])

    const name = playlist ? playlist.name : tracks.length != 0 ? "Your Tracks" : "loading..."
    const owner = playlist ? playlist.ownerUsername : tracks && username ? username : ""
    const canEdit = !!(playlist && playlist.ownerUsername == getUserSession()?.userInfo?.username)

    const { snackSuccess, snackError } = useSnack()

    return (
        <Grid container style={{ height: "100vh", paddingLeft: `${DRAWER_SIZE}px` }}>
            <Grid direction="column" item xs={5} md={4}>
                <Header title={name} subtitle={owner} setPlayerOpen={playerOpen ? setPlayerOpen : undefined} />
                {track !== undefined && playerOpen && (
                    <div>
                        <Player track={track} handleProgress={handleProgress} />
                    </div>
                )}
                <Tracklist
                    tracks={tracks}
                    height={playerOpen ? `calc(70vh - ${DRAWER_SIZE}px)` : `calc(100vh - ${DRAWER_SIZE}px)`}
                    handleClick={handleSelectTrack}
                    canEdit={true}
                    handleUpdate={updateTrack}
                    playlists={playlists}
                    updatePlaylists={() => fetchLoggedInPlaylists()}
                />
            </Grid>
            <Grid direction="column" item xs={7} md={8}>
                <TabInterface
                    track={track}
                    updateTrackTab={updateTrackTab}
                    deleteTab={deleteTrackTab}
                    canEdit={canEdit}
                    timestamp={timestamp}
                />
            </Grid>
        </Grid>
    )

    function handleSelectTrack(index?: number) {
        setTrackIndex(index)
        if (index !== undefined) setPlayerOpen(true)
    }

    function fetchPlaylist(playlistId: string) {
        getPlaylist(playlistId)
            .then((playlist) => {
                snackSuccess(`Successfully fetched the playlist`)
                setPlaylist(playlist)
            })
            .catch((err) =>
                snackError(`An error happened while fetching the playlist ${playlistId} tracks: ${err.message}`)
            )
    }

    function fetchPlaylistTracks(playlistId: string) {
        getPlaylistTracks(playlistId)
            .then((tracks) => {
                snackSuccess(`Successfully fetched the playlist tracks`)
                setTracks(tracks)
            })
            .catch((err) => snackError(`An error happened while fetching the playlist ${playlistId}: ${err.message}`))
    }

    function fetchUserTracks(username: string) {
        getUserTracks(username)
            .then((tracks) => {
                snackSuccess(`Successfully fetched the user's tracks`)
                setTracks(tracks)
            })
            .catch((err) => snackError(`An error happened while fetching the user's tracks: ${err.message}`))
    }

    function fetchLoggedInPlaylists() {
        getSessionPlaylists()
            .then((playlists) => {
                if (playlists) setPlaylists(playlists)
            })
            .catch((err) => snackError(`An error happened while fetching playlists: ${err.message}`))
    }

    function updateTrackTab(updatedTab: Tab) {
        if (track) {
            const newTabs = [updatedTab, ...track.tabs.filter((tab) => tab.id !== updatedTab.id)]

            const newTracks = [...tracks]
            for (const i in newTracks)
                if (tracks[i].id == track.id) {
                    track.tabs = newTabs
                    break
                }

            setTracks(newTracks)
        }
    }

    function deleteTrackTab(tabId: string) {
        deleteTab(tabId)

        if (track) {
            const newTabs = [...track.tabs.filter((tab) => tab.id !== tabId)]

            const newTracks = [...tracks]
            for (const i in newTracks)
                if (tracks[i].id == track.id) {
                    track.tabs = newTabs
                    break
                }

            setTracks(newTracks)
        }
    }

    function handleProgress(playedSeconds: number) {
        const timestamp = toTimestamp(playedSeconds)

        const id = `tab-breakpoint-${timestamp}`
        const element = document.getElementById(id)
        // console.log(`Scrolling to ${id} to element ${element}`)

        if (element) {
            // Scroll the whole page to top THEN scroll the element 10/10
            element.scrollIntoView({ behavior: "smooth", inline: "nearest" })

            setTimestamp(timestamp)
        } else {
            // alert(`not found with id [tab-breakpoint-${timestamp}]`)
        }
    }

    function toTimestamp(totalSeconds: number) {
        // const hours = Math.floor(totalSeconds / 3600)
        const remainingSeconds = totalSeconds % 3600
        const minutes = Math.floor(remainingSeconds / 60)
        const seconds = Math.floor(remainingSeconds % 60)

        return `${timePadding(minutes)}:${timePadding(seconds)}`
    }

    function timePadding(num: number): string {
        return num > 9 ? num.toString() : `0${num}`
    }

    function updateTrack(updateTrack: TrackInfo) {
        const newTracks = [updateTrack, ...tracks.filter((track) => track.id !== updateTrack.id)]
        setTracks(newTracks)
        snackSuccess("Track updated!")
    }
}

const Header: React.FC<{
    title: string
    subtitle: string
    setPlayerOpen?: (open: boolean) => void
}> = ({ title, subtitle, setPlayerOpen }) => {
    const history = useHistory()
    return (
        <React.Fragment>
            <Paper style={{ padding: "8px", width: "100%", height: "64px" }}>
                <div style={{ display: "flex" }}>
                    <StartIcon onClick={() => history.goBack()}>
                        <BackIcon />
                    </StartIcon>
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <Typography noWrap style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {title}
                        </Typography>
                        <Typography noWrap style={{ fontSize: "12px", fontWeight: 400 }}>
                            {subtitle}
                        </Typography>
                    </div>
                    {setPlayerOpen && (
                        <div style={{ marginLeft: "auto" }}>
                            <ButtonGroup>
                                <IconButton onClick={() => setPlayerOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            </Paper>
        </React.Fragment>
    )
}

const Player: React.FC<{ track: TrackInfo; handleProgress: (progress: number) => void }> = ({
    track,
    handleProgress,
}) => {
    return (
        <React.Fragment>
            <div>
                <Paper style={{ width: "100%", height: "30vh", textAlign: "center" }}>
                    <ReactPlayer
                        url={`https://www.youtube.com/watch?v=${track.youtubeLink}`}
                        controls={true}
                        width="100%"
                        height="30vh"
                        onProgress={(state) => handleProgress(state.playedSeconds)}
                    />
                </Paper>
            </div>
        </React.Fragment>
    )
}

const ErrorPage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div style={{ paddingLeft: `calc(${DRAWER_SIZE}px + 24px)` }}>
            <h1>An error happened</h1>
            <b>{message}</b>
        </div>
    )
}
