import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
} from "@mui/material"
import React, { useState } from "react"
import { Tab } from "../common/dto"
import { Difficulty, Instrument, Mastery } from "../common/enum"
import { CreateTabDto } from "../integrations/tabuation/dto"
import { addTabToTrack } from "../integrations/tabuation/tabs"

const FormBox = styled(Box)(({ theme }) => ({
    "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
    },
}))

export interface Props {
    username: string
    handleClose: () => void
    handleSuccess: () => void
}

const initialState = {
    name: "",
    tabText: "",
    difficulty: Difficulty.Beginner,
    mastery: Mastery.Basic,
    instrument: Instrument.ElectricGuitar,
}
type State = typeof initialState

export const AddTabDialog: React.FC<{
    trackId: string
    handleClose: () => void
    handleSuccess: (newTab: Tab) => void
}> = ({ trackId, handleClose, handleSuccess }) => {
    const [state, setState] = useState<State>(initialState)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setState({ ...state, [event.target.name]: event.target.value })
    }
    // TODO make this generic
    const handleDifficultyChange = (event: SelectChangeEvent<Difficulty>) => {
        setState({ ...state, difficulty: event.target.value as Difficulty })
    }
    const handleMasteryChange = (event: SelectChangeEvent<Mastery>) => {
        setState({ ...state, mastery: event.target.value as Mastery })
    }
    const handleInstrumentChange = (event: SelectChangeEvent<Instrument>) => {
        setState({ ...state, instrument: event.target.value as Instrument })
    }

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle id="form-dialog-title">Create a new tab</DialogTitle>
            <FormBox
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault()
                    addTab()
                }}
            >
                <DialogContent>
                    <TextField label="Tab title" required name="name" type="text" onChange={handleChange} />
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Difficulty</InputLabel>
                        <Select value={state.difficulty} onChange={handleDifficultyChange}>
                            {Object.values(Difficulty)
                                .filter((x) => isNaN(parseInt(x)))
                                .map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Mastery</InputLabel>
                        <Select value={state.mastery} onChange={handleMasteryChange}>
                            {Object.values(Mastery)
                                .filter((x) => isNaN(parseInt(x)))
                                .map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Instrument</InputLabel>
                        <Select value={state.instrument} onChange={handleInstrumentChange}>
                            {Object.values(Instrument)
                                .filter((x) => isNaN(parseInt(x)))
                                .map((type) => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button type="submit">Add Tab</Button>
                </DialogActions>
            </FormBox>
        </Dialog>
    )

    function addTab() {
        const requestDto: CreateTabDto = {
            name: state.name,
            tabText: "",
            difficulty: state.difficulty,
            mastery: state.mastery,
            instrument: state.instrument,
        }

        addTabToTrack(trackId, requestDto)
            .then((newTab) => handleSuccess(newTab))
            .catch((err: Error) => alert(err.message))
    }
}
