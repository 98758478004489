import axios, { AxiosError, AxiosResponse } from "axios"
import { YoutubePlaylist, YoutubePlaylistItemsResponse, YoutubePlaylistResponse, YoutubeSearchResponse } from "./dto"
import { MOCKED_PLAYLIST, MOCKED_PLAYLIST_ITEMS_RESPONSE, MOCKED_SEARCH_VIDEO_RESPONSE } from "./mockedResponses"

const API_KEY = "AIzaSyC1n6Zq88ldDa6WPu_18P6gJmqbj940Tfw"

const PLAYLIST_BASE_URL = "https://youtube.googleapis.com/youtube/v3/playlists"
const PLAYLIST_URL = `${PLAYLIST_BASE_URL}?key=${API_KEY}&part=snippet`

const PLAYLIST_ITEMS_BASE_URL = "https://youtube.googleapis.com/youtube/v3/playlistItems"
const PLAYLIST_ITEMS_URL = `${PLAYLIST_ITEMS_BASE_URL}?key=${API_KEY}&part=snippet&part=contentDetails&maxResults=25`

const SEARCH_BASE_URL = "https://youtube.googleapis.com/youtube/v3/search"
const SEARCH_URL = `${SEARCH_BASE_URL}?key=${API_KEY}&part=snippet&maxResults=25`
const SEARCH_VIDEOS_URL = `${SEARCH_URL}&type=video`

export const searchVideos = (searchTerm: string, mocked: boolean): Promise<any> => {
    if (mocked) return Promise.resolve(MOCKED_SEARCH_VIDEO_RESPONSE)

    return axios
        .get(`${SEARCH_URL}&type=video&q=${searchTerm}`)
        .then(handleResponse)
        .then((res: YoutubeSearchResponse) => {
            // alert(JSON.stringify(res.items, null, 4))
            res.items.forEach((item) => {
                item.snippet.channelTitle = replaceSingleQuote(item.snippet.channelTitle)
                item.snippet.title = replaceSingleQuote(item.snippet.title)
            })
            // alert(JSON.stringify(res.items, null, 4))
            return res
        })
        .catch(handleError)
}

export const searchPlaylists = (searchTerm: string, mocked: boolean): Promise<any> => {
    if (mocked) return Promise.resolve(MOCKED_SEARCH_VIDEO_RESPONSE)

    return axios
        .get(`${SEARCH_URL}&type=playlist&q=${searchTerm}`)
        .then(handleResponse)
        .then((res: YoutubeSearchResponse) => {
            // alert(JSON.stringify(res.items, null, 4))
            res.items.forEach((item) => {
                item.snippet.channelTitle = replaceSingleQuote(item.snippet.channelTitle)
                item.snippet.title = replaceSingleQuote(item.snippet.title)
            })
            // alert(JSON.stringify(res.items, null, 4))
            return res
        })
        .catch(handleError)
}

export const getPlaylist = (playlistId: string, mocked: boolean): Promise<YoutubePlaylist> => {
    if (mocked) return Promise.resolve(MOCKED_PLAYLIST)

    return axios
        .get(`${PLAYLIST_URL}&id=${playlistId}`)
        .then(handleResponse)
        .then((data: YoutubePlaylistResponse) => data.items[0])
        .catch(handleError)
}

export const getPlaylistItems = (playlistId: string, mocked: boolean): Promise<YoutubePlaylistItemsResponse> => {
    if (mocked) return Promise.resolve(MOCKED_PLAYLIST_ITEMS_RESPONSE)

    return axios
        .get(`${PLAYLIST_ITEMS_URL}&playlistId=${playlistId}`)
        .then(handleResponse)
        .then((res: YoutubePlaylistItemsResponse) => {
            res.items.forEach((item) => {
                item.snippet.channelTitle = replaceSingleQuote(item.snippet.channelTitle)
                item.snippet.title = replaceSingleQuote(item.snippet.title)
                item.snippet.videoOwnerChannelTitle = replaceSingleQuote(item.snippet.videoOwnerChannelTitle)
            })
            // alert(JSON.stringify(res, null, 4))
            return res
        })
        .catch(handleError)
}

const handleResponse = (res: AxiosResponse<any>) => {
    // alert(`status=${res.status}, statusText=${res.statusText}, data=${JSON.stringify(res.data, null, 4)}`)
    return res.data
}

const handleError = (err: AxiosError) => {
    // alert(`error caught: error=${err.name}, message=${err.message}, config=${JSON.stringify(err.config, null, 4)}`)
    throw err
}

const replaceSingleQuote = (rawString: string): string => rawString.replaceAll("&#39;", "'")
