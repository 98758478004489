import { Button, Grid, TextField } from "@mui/material"
import React, { FormEvent } from "react"

interface Props {
    label: string
    placeholder: string
    onSubmit: (event: FormEvent<HTMLFormElement>, searchTerm: string) => void
}

export const SearchForm = ({ label, placeholder, onSubmit }: Props): JSX.Element => {
    const [term, setTerm] = React.useState<string>("")

    return (
        <form onSubmit={(event) => onSubmit(event, term)}>
            <Grid container>
                <TextField
                    id="searchTerm"
                    label={label}
                    type="text"
                    value={term}
                    placeholder={placeholder}
                    onChange={(ev) => setTerm(ev.target.value)}
                />
                <Button
                    variant="contained"
                    type="submit"
                    style={{ marginLeft: "5px", marginTop: "10px" }}
                    disabled={term.length == 0}
                >
                    Search
                </Button>
            </Grid>
        </form>
    )
}
