import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField } from "@mui/material"
import React, { useState } from "react"
import { TrackInfo } from "../../../common/dto"
import { updateTrack } from "../../../integrations/tabuation/tracks"

const FormBox = styled(Box)(({ theme }) => ({
    "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
    },
}))

export interface Props {
    open: boolean
    track: TrackInfo
    handleClose: () => void
    handleSuccess: (updatedTrack: TrackInfo) => void
}

export default function EditTrackDialog(props: Props): JSX.Element {
    const { open, track, handleClose, handleSuccess } = props
    const [state, setState] = useState({
        title: track.title,
        artists: track.artists,
        youtubeLink: track.youtubeLink,
        spotifyLink: track.spotifyLink,
        soundcloudLink: track.soundcloudLink,
    })

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setState({ ...state, [event.target.name]: event.target.value })
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit track</DialogTitle>
            <FormBox
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault()
                    editTrack()
                }}
            >
                <DialogContent>
                    <TextField
                        label="Song title"
                        required
                        name="title"
                        type="text"
                        onChange={handleChange}
                        defaultValue={track.title}
                    />
                    <TextField
                        label="Artist(s)"
                        name="artists"
                        type="text"
                        onChange={handleChange}
                        defaultValue={track.artists}
                    />
                    <TextField
                        label="Youtube Link"
                        name="youtubeLink"
                        type="text"
                        onChange={handleChange}
                        defaultValue={track.youtubeLink}
                    />
                    <TextField
                        label="Spotify Link"
                        name="spotifyLink"
                        type="text"
                        onChange={handleChange}
                        defaultValue={track.spotifyLink}
                    />
                    <TextField
                        label="SoundCloud Link"
                        name="soundcloudLink"
                        type="text"
                        onChange={handleChange}
                        defaultValue={track.soundcloudLink}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button type="submit">Edit</Button>
                </DialogActions>
            </FormBox>
        </Dialog>
    )

    function editTrack() {
        const payload = {
            title: state.title,
            artists: state.artists,
            youtubeLink: state.youtubeLink,
            spotifyLink: state.spotifyLink,
            soundcloudLink: state.soundcloudLink,
        }

        updateTrack(track.id, payload)
            .then((res) => {
                handleSuccess(res)
            })
            .catch((err) => window.alert("Error fetching api:" + JSON.stringify(err, null, 4)))
    }
}
