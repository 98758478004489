import firebase from "../config/firebase"

export interface AuthData {
    userInfo: firebase.User
    credentials: firebase.auth.AuthCredential
}

export const firebaseAuthPopup = (provider: firebase.auth.AuthProvider): Promise<AuthData> => {
    return firebase.auth().signInWithPopup(provider).then(handleFirebaseAuthResult).catch(handleFirebaseAuthError)
}

export const firebaseEmailPasswordSignIn = (email: string, password: string): Promise<AuthData> => {
    return firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(handleFirebaseAuthResult)
        .catch(handleFirebaseAuthError)
}

export const firebaseEmailPasswordLogIn = (email: string, password: string): Promise<AuthData> => {
    return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(handleFirebaseAuthResult)
        .catch(handleFirebaseAuthError)
}

const handleFirebaseAuthResult = (res: firebase.auth.UserCredential): AuthData => {
    // console.log(JSON.stringify(res.credential, null, 4))

    const user: firebase.User | null = res.user
    const credentials: firebase.auth.AuthCredential | null = res.credential
    // console.log(credentials)

    if (!user || !credentials) {
        throw Error("Not enough information returned to authenticate user")
    }

    return { userInfo: user, credentials: credentials }
}

const handleFirebaseAuthError = (error: any) => {
    console.log("error caught while authenticating with firebase auth " + error)
    throw error
}
