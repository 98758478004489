import { Typography } from "@mui/material"
import React, { useEffect } from "react"
import { TabData } from "../../../base/dto"

export const TabWithBreakpoints: React.FC<{
    tabData: TabData
    breakpoint: string
    setBreakpoint: (newBreakpoint: string) => void
    timestamp: string
}> = ({ tabData, breakpoint, setBreakpoint, timestamp }) => {
    if (breakpoint) {
        console.log(`breakpoint changed to ${breakpoint}, scrolling...`)
        scrollToElement(breakpoint)
    }

    useEffect(() => {
        if (breakpoint != timestamp) {
            console.log(`timestamp changed to ${timestamp}, changing breakpoint...`)
            setBreakpoint(timestamp)
        } else {
            console.log(`breakpoint changed to ${breakpoint}, scrolling...`)
            scrollToElement(breakpoint)
        }
    }, [timestamp])

    return (
        <React.Fragment>
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    overflow: "scroll",
                    padding: "8px",
                    /** 
                    position: "absolute",
                    top: "150px",
                    bottom: "0",
                    left: "35%",
                    right: 0,
                    overflow: "auto",
                    background: "blue",
                    border: "1px solid red",
                    */
                }}
            >
                <RenderTab tabData={tabData} />
            </div>
        </React.Fragment>
    )

    function scrollToElement(timestamp: string) {
        const id = `tab-breakpoint-${timestamp}`
        const element = document.getElementById(id)

        // console.log(`Scrolling to ${id} to element ${element}`)

        if (element) {
            // Scroll the whole page to top THEN scroll the element 10/10
            element.scrollIntoView({ behavior: "smooth", inline: "nearest" })
        } else {
            // alert(`not found with id [tab-breakpoint-${timestamp}]`)
        }
    }
}

const RenderTab: React.FC<{ tabData: TabData }> = ({ tabData }) => {
    return (
        <React.Fragment>
            <>
                <div id={"tab-breakpoint-start"} />
                {tabData.lines.map((line, i) => (
                    <>
                        {line.breakpoint != null ? (
                            <div
                                key={`tab-breakpoint-${line.breakpoint?.timestamp}`}
                                id={`tab-breakpoint-${line.breakpoint?.timestamp}`}
                            />
                        ) : (
                            <></>
                        )}
                        <RenderLine key={`tab-details-line-${i}`} line={line.text} />
                    </>
                ))}
            </>
        </React.Fragment>
    )
}

const RenderLine: React.FC<{ line: string }> = ({ line }) => {
    return line ? (
        <Typography
            variant="h6"
            style={{
                textAlign: "left",
                margin: "0px",
                whiteSpace: "pre",
            }}
        >
            {line}
        </Typography>
    ) : (
        <br />
    )
}
