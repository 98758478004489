import { Button, ButtonGroup, Card, Divider, Grid, MenuItem, TextField } from "@mui/material"
import React, { useState } from "react"
import { useSnack } from "../../../base/Snackbar"
import { Tab, TrackInfo } from "../../../common/dto"
import { processTabSearchString } from "../../../common/utils"
import { updateTabText } from "../../../integrations/tabuation/tabs"
import { AddTabModal } from "./AddTabModal"
import { DeleteTabModal } from "./DeleteTabModal"
import { TabEditor } from "./TabEditor"

export const TabInterface: React.FC<{
    track: TrackInfo | undefined
    updateTrackTab: (updatedTab: Tab) => void
    deleteTab: (tabId: string) => void
    canEdit: boolean
    timestamp: string
}> = ({ track, updateTrackTab, deleteTab, canEdit, timestamp }) => {
    const tabs = (track && track.tabs ? track.tabs : []).sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )

    console.log(`Tab interface render, timestamp=${timestamp}`)

    const [editing, setEditing] = useState<boolean>(false)

    const hasTabs = tabs.length > 0
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [tempTab, setTempTab] = useState<string | undefined>(undefined)

    const tabIndexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.value)
        setTabIndex(index)
        setTempTab(tabs[index].tabText)
    }

    const selectedTab: Tab | undefined = tabIndex !== undefined ? tabs[tabIndex] : undefined

    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
    const [deleteTabDialogOpen, setDeleteTabDialogOpen] = useState<boolean>(false)

    const { snackSuccess, snackError } = useSnack()
    return (
        <React.Fragment>
            <Card style={{ height: "100vh" }}>
                <TabsHeader />
                <Divider />

                <Grid
                    container
                    style={{
                        height: "calc(100vh - 64px - 2px)",
                        width: "100%",
                    }}
                >
                    <TabEditor
                        hasTab={selectedTab !== undefined}
                        editing={editing}
                        tabText={editing ? tempTab : selectedTab?.tabText}
                        setTempTab={setTempTab}
                        timestamp={timestamp}
                    />
                </Grid>
            </Card>
        </React.Fragment>
    )

    function TabsHeader(): JSX.Element {
        return (
            <React.Fragment>
                <div id="Tabs-header-paper" style={{ display: "flex", padding: "12px" }}>
                    {track && (
                        <div style={{ marginTop: "auto" }}>
                            <Button
                                href={`https://www.ultimate-guitar.com/search.php?search_type=title&value=${processTabSearchString(
                                    track.title
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                                variant="contained"
                            >
                                Search tabs
                            </Button>
                        </div>
                    )}
                    <div style={{ paddingLeft: "16px", paddingRight: "8px" }}>
                        {hasTabs ? (
                            <TextField
                                select
                                placeholder="Saved tabs"
                                value={tabIndex}
                                onChange={tabIndexChange}
                                size="small"
                            >
                                {tabs.map((tab, index) => (
                                    <MenuItem key={index} value={index}>
                                        {tab.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <p>No tabs saved</p>
                        )}
                    </div>
                    <div style={{ marginLeft: "auto", marginTop: "auto" }}>
                        <HeaderButtons selectedTab={selectedTab} canEdit={canEdit} editing={editing} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    function changeTabText(tabId: string, newTabText: string | undefined) {
        if (!newTabText) {
            snackError(`New tab text is undefined`)
        } else {
            updateTabText(tabId, newTabText)
                .then((tab) => {
                    updateTrackTab(tab)
                    snackSuccess("Tab text updated!")
                })
                .catch((err) => snackError(`An error happened updating the tab text: ${err.message}`))
        }
    }

    function HeaderButtons(props: { selectedTab: Tab | undefined; canEdit: boolean; editing: boolean }) {
        const { selectedTab, canEdit, editing } = props

        return (
            <ButtonGroup>
                {selectedTab &&
                    canEdit &&
                    (editing ? (
                        <>
                            <Button variant="outlined" onClick={() => setEditing(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    changeTabText(selectedTab.id, tempTab)
                                    setEditing(false)
                                }}
                                disabled={tempTab === undefined}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setTempTab(selectedTab.tabText)
                                setEditing(true)
                            }}
                        >
                            Edit
                        </Button>
                    ))}
                {track && canEdit && !editing && (
                    <>
                        {selectedTab && (
                            <>
                                <Button variant="outlined" onClick={() => setDeleteTabDialogOpen(true)}>
                                    Delete
                                </Button>
                                <DeleteTabModal
                                    open={deleteTabDialogOpen}
                                    setOpen={setDeleteTabDialogOpen}
                                    deleteTab={() => deleteTab(selectedTab.id)}
                                />
                            </>
                        )}
                        <Button variant="outlined" onClick={() => setAddDialogOpen(true)}>
                            Add
                        </Button>
                        <AddTabModal
                            track={track}
                            open={addDialogOpen}
                            setOpen={setAddDialogOpen}
                            updateTrackTab={updateTrackTab}
                        />
                    </>
                )}
            </ButtonGroup>
        )
    }
}
