import { Modal } from "@mui/material"
import React from "react"
import { Tab, TrackInfo } from "../../../common/dto"
import { AddTabDialog } from "../../../tabs/AddTabDialog"

export const AddTabModal: React.FC<{
    track: TrackInfo
    open: boolean
    setOpen: (open: boolean) => void
    updateTrackTab: (newTab: Tab) => void
}> = ({ track, open, setOpen, updateTrackTab }) => {
    return (
        <React.Fragment>
            <Modal open={open} onClose={() => setOpen(false)}>
                <AddTabDialog
                    trackId={track.id}
                    handleClose={() => setOpen(false)}
                    handleSuccess={(newTab: Tab) => {
                        setOpen(false)
                        updateTrackTab(newTab)
                    }}
                />
            </Modal>
        </React.Fragment>
    )
}
