import { TabBreakpointInfo, TabData, TabLine } from "./dto"

export const fromSnakeCaseToText = (str: string): string => {
    return str.toLowerCase().replace(/_/g, " ")
}

export const isEmpty = (obj: any): boolean => {
    return Object.keys(obj).length === 0
}

export const splitLines = (str: string): string[] => str.split("\n")

export const breakpointRegex = /^(\[([0-2])?[0-3]:[0-5][0-9]\]) *([0-9A-z]*)?/gm

export const trimBreakpointInfo = (rawString: string): string | undefined =>
    rawString ? rawString.replace(/[[\]]/g, "") : undefined

export const processTab = (rawTab: string): TabData => {
    const lines: TabLine[] = []

    splitLines(rawTab).forEach((text, index) => {
        const result = breakpointRegex.exec(text)
        let breakpoint: TabBreakpointInfo | undefined = undefined

        if (result && result[0]) {
            // alert(JSON.stringify(result, null, 4))

            breakpoint = {
                name: trimBreakpointInfo(result[3]),
                timestamp: trimBreakpointInfo(result[1]),
            }
        }

        lines.push({
            line: index,
            text: text,
            breakpoint: breakpoint,
        })
    })

    // alert(JSON.stringify(lines, null, 4))

    const timestamps = lines
        .map((line) => line.breakpoint?.timestamp)
        .flatMap((timestamp) => (timestamp ? [timestamp] : []))

    return { lines, timestamps }
}

export const asTime = (totalSeconds: number): string => {
    const remainingSeconds = totalSeconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    const seconds = Math.floor(remainingSeconds % 60)

    return `${timePadding(minutes)}:${timePadding(seconds)}`
}

export const timePadding = (num: number): string => {
    return num > 9 ? num.toString() : `0${num}`
}

export const trimTrackName = (track: string, artist: string): string => {
    const regex = new RegExp(`^(${artist})(\s)*[-/:]?(\s)*`, "i")
    return track.replace(regex, "")
}
