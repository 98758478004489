import { createStyles, Link, styled, Theme } from "@mui/material"
import React from "react"
import { NavLink } from "react-router-dom"

const CustomNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: "none",
}))

export const ListHeader: React.FC<{
    header: string
    subHeader?: string
    to?: string
}> = ({ header, subHeader, to }) => {
    return (
        <React.Fragment>
            <h2>
                {to ? (
                    <Link underline="hover">
                        <CustomNavLink to={to}>{header}</CustomNavLink>
                    </Link>
                ) : (
                    header
                )}
            </h2>
            {subHeader && <p>{subHeader}</p>}
        </React.Fragment>
    )
}
