import { Button, ButtonGroup, Divider, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { getUserSession } from "../base/session"
import { useSnack } from "../base/Snackbar"
import { DEFAULT_SONG_THUMBNAIL_URL, DRAWER_SIZE } from "../common/constants"
import { PlaylistInfo } from "../common/dto"
import { processTabSearchString } from "../common/utils"
import { CreateTrackDto } from "../integrations/tabuation/dto"
import { createPlaylist, getUserPlaylists } from "../integrations/tabuation/playlists"
import { getBestThumbnailOrDefault, YoutubeSearchResponse, YoutubeSearchResult } from "../integrations/youtube/dto"
import { getPlaylistItems, searchPlaylists, searchVideos } from "../integrations/youtube/youtubeProvider"
import { SearchType } from "./dto"
import YoutubeSearchForm from "./YoutubeSearchForm"
import YoutubeVideoGrid from "./YoutubeVideoGrid"
import { YoutubeVideoOptions } from "./YoutubeVideoOptions"

const PLACEHOLDER_SEARCH_TERM = "Cage the Elephant"
const YOUTUBE_VIDEO_TYPE = ""
const YOUTUBE_PLAYLIST_TYPE = ""

/**
 * [Choose Tracks|Playlists|Artists] [form to input the name] [Go/Search]
 *
 * If track -> show list of tracks -> play track (list of tracks as playlist?)-> add track
 * If Playlist -> show list of playlists -> play playlist -> add playlist or individual tracks
 * If artist -> show tracks & playlists/albums
 *
 * Username is only used for logged in users
 */
export default function YoutubeSearchPage(): JSX.Element {
    const username = getUserSession()?.userInfo?.username
    const [playlists, setPlaylists] = useState<PlaylistInfo[] | undefined>()

    const [searchResponse, setSearchResponse] = useState<YoutubeSearchResponse | undefined>()
    const [searchResult, setSearchResult] = useState<YoutubeSearchResult | undefined>()

    const [trackOptionsAnchorEl, setTrackOptionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const trackOptionHandleClick = (event: React.MouseEvent<HTMLElement>) =>
        setTrackOptionsAnchorEl(event.currentTarget)

    useEffect(() => {
        if (username) updatePlaylists(username)
    }, [username])

    const isVideo = searchResult?.id?.kind == "youtube#video"
    const isPlaylist = searchResult?.id?.kind == "youtube#playlist"
    const playlistId: string | undefined = searchResult?.id?.playlistId

    let url: string | undefined
    if (isVideo) {
        url = `https://www.youtube.com/watch?v=${searchResult.id.videoId}`
    } else if (isPlaylist) {
        url = `https://www.youtube.com/playlist?list=${playlistId}`
    }

    const { snackSuccess, snackError } = useSnack()
    return (
        <Grid container style={{ paddingLeft: `${DRAWER_SIZE}px`, paddingRight: `${DRAWER_SIZE}px` }}>
            <Grid xs={searchResult ? 6 : 12}>
                <Grid xs={12} sx={{ display: "flex", justifyContent: "center", paddingTop: "16px" }}>
                    <YoutubeSearchForm placeholder={PLACEHOLDER_SEARCH_TERM} handleSubmit={handleSearchSubmit} />
                </Grid>
                {searchResponse?.items ? (
                    <Grid style={{ maxHeight: "calc(100vh - 104px)", overflowY: "scroll", width: "100%" }}>
                        <YoutubeVideoGrid
                            videos={searchResponse.items}
                            onVideoClick={(video) => setSearchResult(video)}
                            playlists={playlists}
                            updatePlaylists={username ? () => updatePlaylists(username) : undefined}
                        />
                    </Grid>
                ) : (
                    <h3 style={{ textAlign: "center" }}>Search a video</h3>
                )}
            </Grid>
            {searchResult && url && (
                <Grid xs={6} style={{ padding: "8px" }}>
                    <MediaPlayer searchResult={searchResult} />
                    <Divider style={{ marginTop: "8px", marginBottom: "8px" }}></Divider>
                    <MediaPlayerActions searchResult={searchResult} />
                </Grid>
            )}
        </Grid>
    )

    function handleSearchSubmit(searchType: SearchType, searchTerm: string, mocked: boolean) {
        if (searchType == SearchType.TRACK) {
            searchVideos(searchTerm, mocked)
                .then(setSearchResponse)
                .catch((e) => snackError(`An error happened while searching videos: ${e.message}`))
        } else if (searchType == SearchType.PLAYLIST) {
            searchPlaylists(searchTerm, mocked)
                .then(setSearchResponse)
                .catch((e) => snackError(`An error happened while searching playlists: ${e.message}`))
        } else {
            snackError(`Search type ${searchType} not implemented yet ;__;`)
        }
    }

    function updatePlaylists(username: string) {
        getUserPlaylists(username)
            .then(setPlaylists)
            .catch((err) => snackError(`Couldn't update playlists: ${err.message}`))
    }

    function addPlaylist(playlistId: string, playlists: PlaylistInfo[], playlistSearchResult: YoutubeSearchResult) {
        getPlaylistItems(playlistId, false)
            .then((res) => {
                const createTracks: CreateTrackDto[] = res.items.map((video) => {
                    const thumbnailUrl = getBestThumbnailOrDefault(video.snippet.thumbnails, undefined)

                    return {
                        title: video.snippet.title,
                        artists: video.snippet.channelTitle,
                        youtubeLink: video.contentDetails.videoId,
                        thumbnailUrl: thumbnailUrl,
                    }
                })

                const thumbnailUrl = playlistSearchResult.snippet.thumbnails.standard
                    ? playlistSearchResult.snippet.thumbnails.standard.url
                    : DEFAULT_SONG_THUMBNAIL_URL

                createPlaylist({
                    name: playlistSearchResult.snippet.title,
                    public: false,
                    description: playlistSearchResult.snippet.description,
                    pictureUrl: thumbnailUrl,
                    tracks: createTracks,
                })
                    .then((playlist) => setPlaylists([...playlists, playlist]))
                    .then(() => snackSuccess("Playlist created!"))
                    .catch((err) => snackError(`An error happened while creating the playlist: ${err.message}`))
            })
            .catch((err) => snackError(`Couldn't update playlists: ${err.message}`))
    }

    function MediaPlayer(props: { searchResult: YoutubeSearchResult }): JSX.Element {
        const result = props.searchResult
        return (
            <React.Fragment>
                <ReactPlayer url={url} controls={true} width="100%" style={{ paddingBlock: "8px" }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                    }}
                >
                    {result.snippet.title}
                </Typography>

                <Typography sx={{ fontWeight: "bold", paddingTop: "8px", paddingBottom: "8px" }} variant="subtitle1">
                    {result.snippet.channelTitle}
                </Typography>

                {/*alert(searchResult.snippet.description)*/}
                <Typography variant="body1">{result.snippet.description}</Typography>
            </React.Fragment>
        )
    }

    function MediaPlayerActions(props: { searchResult: YoutubeSearchResult }): JSX.Element {
        const result = props.searchResult
        return (
            <Grid container>
                <ButtonGroup>
                    {isVideo && (
                        <>
                            <Button
                                href={`https://www.ultimate-guitar.com/search.php?search_type=title&value=${processTabSearchString(
                                    result.snippet.title
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                                variant="contained"
                            >
                                {"Search tabs >>>"}
                            </Button>
                            {!username && <Typography sx={{ p: 1 }}>Login to add to a playlist</Typography>}
                        </>
                    )}
                    {isVideo && username && playlists && (
                        <>
                            <Button variant="contained" onClick={trackOptionHandleClick}>
                                Add Track...
                            </Button>
                            <YoutubeVideoOptions
                                anchorEl={trackOptionsAnchorEl}
                                handleClose={() => setTrackOptionsAnchorEl(null)}
                                video={result}
                                playlists={playlists}
                                updatePlaylists={() => updatePlaylists(username)}
                            ></YoutubeVideoOptions>
                        </>
                    )}
                    {playlistId && username && playlists && (
                        <>
                            <Button variant="contained" onClick={() => addPlaylist(playlistId, playlists, result)}>
                                Add Playlist...
                            </Button>
                            <Button variant="contained" onClick={() => alert("Still todo!")}>
                                Add Track...
                            </Button>
                        </>
                    )}
                </ButtonGroup>
            </Grid>
        )
    }
}
