export interface YoutubePlaylistItemsResponse {
    items: YoutubeVideo[]
    pageInfo: PageInfo
}

export interface YoutubeVideo {
    id: string
    snippet: YoutubeVideoSnippet
    contentDetails: ContentDetails
}

export interface YoutubeVideoSnippet {
    publishedAt: string // "2021-04-03T11:30:19Z"
    channelId: string
    title: string
    description: string
    thumbnails: Thumbnails
    channelTitle: string
    playlistId?: string
    position?: number
    resourceId: {
        kind: string
        videoId: string
    }
    videoOwnerChannelTitle: string
    videoOwnerChannelId: string
}

export interface ContentDetails {
    videoId: string
    videoPublishedAt: string // "2018-09-15T09:40:29Z"
}

export interface PageInfo {
    totalResults: number
    resultsPerPage: number
}

export interface YoutubePlaylist {
    id: string
    snippet: YoutubePlaylistSnippet
}

export interface YoutubePlaylistResponse {
    pageInfo: PageInfo
    items: YoutubePlaylist[]
}

export interface YoutubePlaylistSnippet {
    publishedAt: string
    channelId: string
    title: string
    description: string
    thumbnails: Thumbnails
    channelTitle: string
    localized: PlaylistInfo
}

export interface PlaylistInfo {
    title: string
    description: string
}

export interface Thumbnails {
    standard?: Thumbnail
    default?: Thumbnail
    medium?: Thumbnail
    high?: Thumbnail
    maxres?: Thumbnail
}

export const getBestThumbnailOrDefault = (thumbnails: Thumbnails, defaultUrl?: string): string | undefined => {
    if (thumbnails.maxres) return thumbnails.maxres.url
    if (thumbnails.high) return thumbnails.high.url
    if (thumbnails.medium) return thumbnails.medium.url
    if (thumbnails.standard) return thumbnails.standard.url
    if (thumbnails.default) return thumbnails.default.url
    return defaultUrl
}

export interface Thumbnail {
    url: string
    width: number
    height: number
}
export interface YoutubeSearchResponse {
    kind: string
    etag: string
    nextPageToken?: string
    prevPageToken?: string
    regionCode?: string
    pageInfo: PageInfo
    items: YoutubeSearchResult[]
}

export interface YoutubeSearchResult {
    kind: string
    etag: string
    id: {
        kind: string
        videoId?: string
        channelId?: string
        playlistId?: string
    }
    snippet: {
        publishedAt: string
        channelId: string
        title: string
        description: string
        thumbnails: Thumbnails
        channelTitle: string
        liveBroadcastContent: string
    }
}
