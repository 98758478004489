import { TabBreakpoint, TabData } from "./dto"

export const splitLines = (str: string) => str.split("\n")

export const breakpointRegex = /^(\[[0-9A-z]*?\])?(\[[0-9][0-9]:[0-9][0-9]\])?/

export const trimBreakpointInfo = (rawString: string): string | undefined =>
    rawString ? rawString.substr(1, rawString.length - 2) : undefined

export const processTab = (rawTab: string): TabData => {
    const details: string[] = []
    const breakpoints: TabBreakpoint[] = []

    let currBreakpoint: TabBreakpoint | undefined = undefined
    splitLines(rawTab).forEach((text, index) => {
        const result = breakpointRegex.exec(text)

        if (result && result[0]) {
            if (currBreakpoint) breakpoints.push(currBreakpoint)

            currBreakpoint = {
                line: index,
                name: trimBreakpointInfo(result[1]),
                timestamp: trimBreakpointInfo(result[2]),
                lines: [],
            }
        }
        if (currBreakpoint) currBreakpoint.lines.push(text)
        else details.push(text)
    })
    // TODO this could duplicate the last breakpoint?
    if (currBreakpoint) breakpoints.push(currBreakpoint)

    return {
        details: details,
        breakpoints: breakpoints,
    }
}

export const asTime = (totalSeconds: number) => {
    const remainingSeconds = totalSeconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    const seconds = Math.floor(remainingSeconds % 60)

    return `${timePadding(minutes)}:${timePadding(seconds)}`
}

export const timePadding = (num: number): string => {
    return num > 9 ? num.toString() : `0${num}`
}
