import { ListItemButton } from "@mui/material"
import { withStyles } from "@mui/styles"
import { COLORS_PRIMARY, COLORS_PRIMARY_DARK } from "../../base/themes"

export const StyledListItemButton = withStyles({
    root: {
        "&$selected": {
            backgroundColor: COLORS_PRIMARY_DARK,
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
        "&$selected:hover": {
            backgroundColor: COLORS_PRIMARY,
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
        "&:hover": {
            backgroundColor: COLORS_PRIMARY,
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white",
            },
        },
    },
    selected: {},
})(ListItemButton)
