import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyCRmu2ju04UWbR7CVejgtmUegE6i8PFwIM",
    authDomain: "tabuation.firebaseapp.com",
    projectId: "tabuation",
    storageBucket: "tabuation.appspot.com",
    messagingSenderId: "230651241605",
    appId: "1:230651241605:web:e1a7f796d5d199292975ec",
    measurementId: "G-QPQGT201JK",
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default firebase

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
googleAuthProvider.setCustomParameters({ prompt: "select_account" })

export const emailAuthProvider = new firebase.auth.EmailAuthProvider()
