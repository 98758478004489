import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React, { useState } from "react"
import { useSnack } from "../base/Snackbar"
import { isEmpty } from "../base/utils"
import { SettingsSwitch } from "../common/components/SettingsSwitch"
import { PlaylistInfo } from "../common/dto"
import { updatePlaylist } from "../integrations/tabuation/playlists"
import { DeletePlaylistDialog } from "./PlaylistDeleteDialog"

export interface UpdatePlaylistDto {
    name?: string
    description?: string
    pictureUrl?: string
    isPublic?: boolean
}

export const PlaylistEditDialog: React.FC<{
    open: boolean
    playlist: PlaylistInfo
    handleClose: () => void
    handleSuccess: (updatedPlaylist: PlaylistInfo) => void
    handleDelete: () => void
}> = ({ open, playlist, handleClose, handleSuccess, handleDelete }) => {
    const [updateDto, setUpdateDto] = useState<UpdatePlaylistDto>({})

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUpdateDto({ ...updateDto, [event.target.name]: event.target.value })
    }

    const isPublic: boolean = updateDto.isPublic !== undefined ? updateDto.isPublic : playlist.isPublic

    const { snackSuccess, snackError } = useSnack()
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Playlist</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    onSubmit={(event: any) => {
                        event.preventDefault()
                        handleSubmit()
                    }}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="ID"
                        type="text"
                        name="ID"
                        defaultValue={playlist.id}
                        variant="standard"
                        disabled
                        style={{ width: "400px" }}
                    />
                    <TextField
                        label="Name"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        defaultValue={playlist.name}
                        variant="standard"
                        style={{ width: "400px" }}
                    />
                    <TextField
                        label="Description"
                        type="text"
                        name="description"
                        onChange={handleChange}
                        defaultValue={playlist.description}
                        variant="standard"
                        style={{ width: "400px" }}
                    />
                    <TextField
                        label="Picture URL"
                        type="text"
                        name="pictureUrl"
                        onChange={handleChange}
                        defaultValue={playlist?.pictureUrl}
                        variant="standard"
                        style={{ width: "400px" }}
                    />

                    <SettingsSwitch
                        name={"isPublic"}
                        label={`Privacy: ${isPublic ? "Public" : "Private"}`}
                        checked={isPublic}
                        disabled={false}
                        handleChange={(event, checked) => setUpdateDto({ ...updateDto, [event.target.name]: checked })}
                    />
                    {/**<pre>{updateDto && JSON.stringify(updateDto, null, 4)}</pre>**/}
                    <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        style={{ width: "100px", backgroundColor: "red", color: "white" }}
                        onClick={() => setDeleteDialogOpen(true)}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                    type="submit"
                    variant="contained"
                    autoFocus
                    onClick={handleSubmit}
                    disabled={isEmpty(updateDto)}
                >
                    Save Changes
                </Button>
            </DialogActions>
            <DeletePlaylistDialog
                open={deleteDialogOpen}
                playlistId={playlist.id}
                handleClose={() => setDeleteDialogOpen(false)}
                handleSuccess={() => {
                    handleClose()
                    handleDelete()
                }}
            />
        </Dialog>
    )

    function handleSubmit() {
        updatePlaylist(playlist.id, updateDto)
            .then((updatedPlaylist) => {
                snackSuccess("Playlist updated!")
                handleSuccess(updatedPlaylist)
                handleClose()
            })
            .catch((err) => snackError(`Unknown error happened: ${err}`))
    }
}
