import { Grid, Link } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSnack } from "../base/Snackbar"
import { DRAWER_SIZE_PX } from "../common/constants"
import { PlaylistInfo } from "../common/dto"
import { ListHeader } from "../common/ListHeader"
import { getPublicPlaylists } from "../integrations/tabuation/playlists"
import { PlaylistCardGrid } from "./PlaylistCardGrid"

export const SearchPlaylistsPage: React.FC = () => {
    const [playlists, setPlaylists] = useState<PlaylistInfo[] | undefined>()

    const { snackError } = useSnack()

    useEffect(() => {
        getPublicPlaylists().then(setPlaylists).catch(handleGetPlaylistsError)
    }, [])

    const history = useHistory()
    return (
        <div style={{ paddingLeft: DRAWER_SIZE_PX }}>
            <div style={{ padding: "24px" }}>
                <Grid container>
                    <ListHeader header={"Public playlists"} to={"/users/"}></ListHeader>
                    <h2 style={{ whiteSpace: "pre-wrap" }}> | </h2>
                    <ListHeader header="Playlists" />
                </Grid>
                {playlists && playlists.length > 0 ? (
                    <PlaylistCardGrid
                        playlists={playlists}
                        canEdit={false}
                        handleUpdate={updatePlaylist}
                        handleDelete={handleDelete}
                    />
                ) : (
                    <>
                        {playlists ? (
                            playlists.length == 0 ? (
                                <span>No playlists found</span>
                            ) : (
                                <></>
                            )
                        ) : (
                            <span>Loading...</span>
                        )}
                        <br></br>
                    </>
                )}
                <Link component="button" variant="body2" onClick={() => history.push("/search/youtube")}>
                    Search for new music on youtube...
                </Link>
            </div>
        </div>
    )

    function updatePlaylist(updated: PlaylistInfo): void {
        if (playlists) setPlaylists([updated, ...playlists.filter((tab) => tab.id !== updated.id)])
    }

    function handleDelete(playlistId: string): void {
        if (playlists) setPlaylists(playlists.filter((playlist) => playlist.id !== playlistId))
    }

    function handleGetPlaylistsError(err: Error): void {
        snackError(`Couldn't get playlists: ${err.message}`)
    }
}
