import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material"
import { SnackbarProvider } from "notistack"
import React, { useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import HomePage from "./base/HomePage"
import NoMatchPage from "./base/NoMatchPage"
import { SnackProvider } from "./base/Snackbar"
import { darkTheme, defaultTheme } from "./base/themes"
import { AppTheme, ThemeType } from "./common/enum"
import { useLocalState } from "./common/localStorageState"
import firebase from "./config/firebase"
import { TabScrollingExperimentPage } from "./experimental/TabScrollingExperimentPage"
import { AbsolutePosPage } from "./NewAppLul/AbsolutePosPage"
import { RelativePosPage } from "./NewAppLul/RelativePosPage"
import SideDrawer from "./NewAppLul/SideDrawer"
import { PlaylistPlayer } from "./Player/MediaPlayerV3/PlaylistPlayer"
import { SearchPlaylistsPage } from "./playlists/SearchPlaylistsPage"
import { UserPlaylistsPage } from "./playlists/UserPlaylistsPage"
import SpotifySearchPage from "./search/SpotifySearchpage"
import YoutubeSearchPage from "./search/YoutubeSearchPage"
import YoutubePlaylistPage from "./tracks/YoutubePlaylistPage"
import SearchUsersPage from "./users/SearchUsersPage"
import { UserPage } from "./users/UserPage"
import { UserSettingsPage } from "./users/UserSettingsPage"

export const ThemeContext = React.createContext<ThemeType>(ThemeType.Light)

export default function App(): JSX.Element {
    const [theme, setTheme] = useLocalState("Theme", AppTheme.DeviceTheme)

    const isDarkMode =
        theme === AppTheme.DarkTheme ||
        (theme === AppTheme.DeviceTheme &&
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
        setTheme(isDarkMode ? AppTheme.DarkTheme : AppTheme.DeviceTheme)
    })

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            firebase.auth().onAuthStateChanged(() => {
                setLoading(false)
            })
        }
    }, [])

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : defaultTheme}>
            <CssBaseline />
            <ThemeContext.Provider value={isDarkMode ? ThemeType.Dark : ThemeType.Light}>
                <SnackbarProvider maxSnack={5}>
                    <SnackProvider>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <BrowserRouter>
                                <div style={{ width: "100%", margin: "0px", padding: "0px" }}>
                                    {/**
                                    <header style={{ height: "80%" }}>
                                        <AppNavBar theme={theme} setTheme={setTheme} />
                                    </header>
                                    */}
                                    <SideDrawer theme={theme} setTheme={setTheme} />
                                    <Switcher theme={theme} setTheme={setTheme} />
                                </div>
                            </BrowserRouter>
                        )}
                    </SnackProvider>
                </SnackbarProvider>
            </ThemeContext.Provider>
        </ThemeProvider>
    )
}
interface SwitcherProps {
    theme: AppTheme
    setTheme: (newValue: AppTheme) => void
}

const Switcher = (props: SwitcherProps) => {
    const { theme, setTheme } = props
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/search/users" component={SearchUsersPage} />
            <Route exact path="/users/self" component={UserPage} />
            <Route exact path="/users/:username" component={UserPage} />
            <Route exact path="/users/:username/settings" component={UserSettingsPage} />
            <Route exact path="/player/:playlistId" component={PlaylistPlayer} />
            <Route exact path="/users/:username/playlists" component={UserPlaylistsPage} />
            <Route exact path="/tracks" component={PlaylistPlayer} />
            <Route exact path="/playlist/:playlistId" component={NoMatchPage} />
            <Route exact path="/library" component={UserPlaylistsPage} />
            <Route exact path="/search/playlists" component={SearchPlaylistsPage} />
            <Route exact path="/search/youtube" component={YoutubeSearchPage} />
            <Route exact path="/search/spotify" component={SpotifySearchPage} />
            <Route exact path="/experimental/positioning/absolute" component={AbsolutePosPage} />
            <Route exact path="/experimental/positioning/relative" component={RelativePosPage} />
            <Route exact path="/experimental/tabScrolling" component={TabScrollingExperimentPage} />
            <Route exact path="/experimental/searchYoutubePlaylistById" component={YoutubePlaylistPage} />
            <Route exact path="/thisIsAtest">
                <SideDrawer theme={theme} setTheme={setTheme} />
            </Route>
            <Route component={NoMatchPage} />
        </Switch>
    )
}
