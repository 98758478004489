import { useSnackbar } from "notistack"
import React from "react"

const noop = () => ({})

interface SnackProviderProps {
    children: React.ReactNode
}

export interface ProviderContext {
    snackSuccess: (message: string) => void
    snackError: (message: string) => void
}

const SnackContext = React.createContext<ProviderContext>({ snackSuccess: noop, snackError: noop })

export function SnackProvider({ children }: SnackProviderProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar()

    const snackSuccess = (message: string) => {
        enqueueSnackbar(message)
    }

    const snackError = (message: string) => {
        enqueueSnackbar(message, { variant: "error" })
    }

    const value = { snackSuccess, snackError }
    return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>
}

export function useSnack(): ProviderContext {
    const context = React.useContext(SnackContext)
    if (context === undefined) {
        throw new Error("useCount must be used within a SnackProvider")
    }
    return context
}
