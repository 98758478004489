import { Button, ButtonGroup, Grid, List, Paper, Typography } from "@mui/material"
import React, { useState } from "react"
import { TrackInfo } from "../common/dto"
import { YoutubeTracklistItem } from "./YoutubeTracklistItem"

interface Props {
    name: string
    owner: string
    tracks: TrackInfo[]
    handleClick: (index: number) => void
    height?: number | string
    openMenu?: (event: React.MouseEvent<HTMLElement>, trackIndex: number) => void
    setChangeOpen?: (open: boolean) => void
    setPlayerOpen?: (open: boolean) => void
}

export default function YoutubeTracklist(props: Props): JSX.Element {
    const { name, owner, tracks, handleClick, height, openMenu, setChangeOpen, setPlayerOpen } = props

    const [selectedIndex, setSelectedIndex] = useState<number>(1)
    const handleListItemClick = (index: number) => {
        setSelectedIndex(index)
        handleClick(index)
    }

    return (
        <React.Fragment>
            <Grid container>
                <Paper style={{ width: "100%", padding: "6px" }}>
                    <Header title={name} subtitle={owner} setChangeOpen={setChangeOpen} setPlayerOpen={setPlayerOpen} />
                </Paper>
                <Grid
                    item
                    style={{
                        width: "100%",
                        flexGrow: 1,
                        flexDirection: "column",
                        overflow: "auto",
                        height: height,
                    }}
                >
                    <List component="nav" dense={true} style={{ height: "90%", width: "100%" }}>
                        {tracks &&
                            tracks.map((track: TrackInfo, index: number) => {
                                return (
                                    <YoutubeTracklistItem
                                        key={`tracklist_${index}`}
                                        selected={index === selectedIndex}
                                        track={track}
                                        handleClick={() => handleListItemClick(index)}
                                        openMenu={
                                            openMenu
                                                ? (event: React.MouseEvent<HTMLElement>) => openMenu(event, index)
                                                : undefined
                                        }
                                    />
                                )
                            })}
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const Header: React.FC<{
    title: string
    subtitle: string
    setChangeOpen?: (open: boolean) => void
    setPlayerOpen?: (open: boolean) => void
}> = ({ title, subtitle, setChangeOpen, setPlayerOpen }) => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Typography noWrap style={{ fontSize: "14px", fontWeight: 500 }}>
                        {title}
                    </Typography>
                    <Typography noWrap style={{ fontSize: "12px", fontWeight: 400 }}>
                        {subtitle}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ alignItems: "right" }}>
                    <ButtonGroup>
                        {setChangeOpen && (
                            <Button variant="contained" onClick={() => setChangeOpen(true)}>
                                Change
                            </Button>
                        )}
                        {setPlayerOpen && (
                            <Button variant="contained" onClick={() => setPlayerOpen(false)}>
                                Close player
                            </Button>
                        )}
                    </ButtonGroup>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
