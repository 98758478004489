import {
    Album,
    ExpandLess,
    ExpandMore,
    Home,
    MusicNote,
    People,
    Person as PersonIcon,
    Search,
} from "@mui/icons-material"
import { default as DarkTheme } from "@mui/icons-material/Brightness3"
import { default as LightTheme } from "@mui/icons-material/Brightness5"
import { default as AppThemeIcon, default as DeviceThemeIcon } from "@mui/icons-material/Brightness6"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MenuIcon from "@mui/icons-material/Menu"
import { Button, ClickAwayListener, Collapse, Grid, List, Menu, MenuItem, Modal } from "@mui/material"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { PageLink, SubPageLinks, ThemePickerState } from "../base/dto"
import { LoginDialog } from "../base/LoginDialog"
import { getUserSession, logout } from "../base/session"
import SignUpDialog from "../base/SignUpDialog"
import { StyledListItemButton } from "../common/components/StyledListItemButton"
import { AppTheme } from "../common/enum"
import ExperimentalIconSvg from "../resources/icons/science-black-18dp.svg"

const ExperimentalIcon: JSX.Element = <img src={ExperimentalIconSvg} />

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}))

const actions = [
    { icon: <DeviceThemeIcon />, name: "Device Theme", value: AppTheme.DeviceTheme },
    { icon: <LightTheme />, name: "Light theme", value: AppTheme.LightTheme },
    { icon: <DarkTheme />, name: "Dark theme", value: AppTheme.DarkTheme },
]

interface Props {
    theme: AppTheme
    setTheme: (newValue: AppTheme) => void
}

const navLinks: (PageLink | SubPageLinks)[][] = [
    [{ label: "Home", path: "/", icon: <Home /> }],
    [
        { label: "Search Users", path: "/search/users", icon: <People /> }, // TODO search/users
        { label: "Search Playlists", path: "/search/playlists", icon: <Album /> },
        { label: "Youtube Search", path: "/search/youtube", icon: <Search /> },
    ],
    [
        {
            label: "Experiments",
            icon: ExperimentalIcon,
            subPages: [
                { label: "Spotify Search", path: "/experimental/search/spotify", icon: ExperimentalIcon },
                { label: "Relative Pos Test", path: "/experimental/positioning/relative", icon: ExperimentalIcon },
                { label: "Absolute Pos Test", path: "/experimental/positioning/absolute", icon: ExperimentalIcon },
                {
                    label: "Youtube Playlist Search by Id",
                    path: "/experimental/searchYoutubePlaylistById",
                    icon: ExperimentalIcon,
                },
            ],
        },
    ],
]

export default function SideDrawer(props: Props): JSX.Element {
    const session = getUserSession()
    const username = session?.userInfo?.username

    const theme = useTheme()
    const [open, setOpen] = React.useState(false)

    const [openLogin, setOpenLogin] = React.useState(false)
    const [openSignup, setOpenSignup] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const [themePickerState, setThemePickerState] = React.useState<ThemePickerState>({ open: false })
    const closeThemePicker = () => setThemePickerState({ open: false, anchorEl: undefined })
    const openThemePicker = (event: React.MouseEvent<HTMLElement>) => {
        setThemePickerState({ open: true, anchorEl: event.currentTarget })
    }

    const myNavLinks: (PageLink | SubPageLinks)[][] = Object.assign([], navLinks)
    if (username) {
        myNavLinks[0] = [
            ...myNavLinks[0],
            { label: "Me", path: `/users/${username}`, icon: <PersonIcon /> },
            { label: "Play Tracks", path: "/tracks", icon: <MusicNote /> },
            { label: "Library", path: "/library", icon: <Album /> }, // TODO /library
        ]
    }

    const history = useHistory()
    const goHome = () => history.push("/")
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Drawer variant="permanent" open={open} onClose={() => setOpen(false)}>
                    <DrawerHeader style={{ marginLeft: "4px" }}>
                        {open ? (
                            <>
                                <IconButton onClick={() => setOpen(!open)}>
                                    {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                                <Button
                                    style={{ marginLeft: "8px" }}
                                    onClick={() => {
                                        goHome()
                                        setOpen(false)
                                    }}
                                >
                                    TABUATION
                                </Button>
                            </>
                        ) : (
                            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
                                <MenuIcon />
                            </IconButton>
                        )}
                    </DrawerHeader>
                    <Divider />
                    {myNavLinks.map((linkGroups, i1) => {
                        return (
                            <>
                                {linkGroups.map((navLink, i2) => {
                                    const key = `nav-group-${i1}-link-${i2}`
                                    if ((navLink as SubPageLinks).subPages) {
                                        return <NavSubPages subPageLinks={navLink as SubPageLinks} key={key} />
                                    } else {
                                        return <NavPage pageLink={navLink as PageLink} key={key} />
                                    }
                                })}
                                <Divider key={`divider-group-${i1}`} />
                            </>
                        )
                    })}

                    <Grid
                        sx={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                            width: "100%",
                        }}
                    >
                        <Divider />
                        <ManagerUser />
                        <ListItem disablePadding sx={{ display: "block" }}>
                            <StyledListItemButton
                                onClick={openThemePicker}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <AppThemeIcon />
                                </ListItemIcon>
                                <ListItemText primary={"App Theme"} sx={{ opacity: open ? 1 : 0 }} />
                            </StyledListItemButton>
                        </ListItem>
                        <AppTheme />
                    </Grid>
                </Drawer>
            </ClickAwayListener>
        </Box>
    )

    function RenderPageLinks(props: { navLinks: (PageLink | SubPageLinks)[] }) {
        return (
            <div>
                {props.navLinks.map((navLink, index) => {
                    const key = `secondary-${index}`
                    if ((navLink as SubPageLinks).subPages) {
                        return <NavSubPages subPageLinks={navLink as SubPageLinks} key={key} />
                    } else {
                        return <NavPage pageLink={navLink as PageLink} key={key} />
                    }
                })}
            </div>
        )
    }

    function NavPage(props: { pageLink: PageLink; key: string }) {
        const { pageLink, key } = props

        return (
            <ListItem disablePadding sx={{ display: "block" }} key={key}>
                <StyledListItemButton
                    onClick={() => {
                        if (history.location.pathname != pageLink.path) history.push(pageLink.path)
                        setOpen(false)
                    }}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {pageLink.icon}
                    </ListItemIcon>
                    <ListItemText primary={pageLink.label} sx={{ opacity: open ? 1 : 0 }} />
                </StyledListItemButton>
            </ListItem>
        )
    }

    function NavSubPages(props: { subPageLinks: SubPageLinks }) {
        const subPages = props.subPageLinks

        const [openCollapse, setOpenCollapse] = React.useState(false)

        function handleOpenSettings() {
            setOpenCollapse(!openCollapse)
        }

        return (
            <div>
                <ListItem button onClick={handleOpenSettings}>
                    <ListItemIcon>{subPages.icon}</ListItemIcon>
                    <ListItemText primary={subPages.label} />
                    {openCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ paddingLeft: "16px" }}>
                        <RenderPageLinks navLinks={subPages.subPages}></RenderPageLinks>
                    </List>
                </Collapse>
            </div>
        )
    }

    function ManagerUser() {
        const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null)

        return (
            <>
                {open ? (
                    <ListItem disablePadding sx={{ display: "block-inline", height: "48px", px: 2.5 }}>
                        <ListItemText
                            style={{
                                justifyContent: "center",
                                margin: "0px",
                                height: "36px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                            }}
                        >
                            {username ? <UserLoggedIn /> : <UserLoggedOff />}
                        </ListItemText>
                    </ListItem>
                ) : (
                    <ListItem disablePadding sx={{ display: "block" }}>
                        <StyledListItemButton
                            sx={{
                                height: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                            onClick={(event: React.MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <PersonIcon />
                            </ListItemIcon>
                        </StyledListItemButton>
                    </ListItem>
                )}
                <Menu
                    open={menuAnchorEl != null}
                    anchorEl={menuAnchorEl}
                    onClick={() => setMenuAnchorEl(null)}
                    onClose={() => setMenuAnchorEl(null)}
                >
                    {username ? <UserLoggedIn /> : <UserLoggedOff />}
                </Menu>
                <Modal open={openLogin} onClose={() => setOpenLogin(false)}>
                    <LoginDialog
                        handleClose={() => {
                            setOpenLogin(false)
                        }}
                    />
                </Modal>
                <Modal open={openSignup} onClose={() => setOpenSignup(false)}>
                    <SignUpDialog
                        handleClose={() => {
                            setOpenSignup(false)
                        }}
                    />
                </Modal>
            </>
        )
    }

    /*
    // ListItem with a non clickable formatted horizontal div, working but not what is needed
    function ManagerUser() {
        return open ? (
            <ListItem
                disablePadding
                sx={{ display: "block-inline", height: "48px", justifyContent: "initial", px: 2.5 }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText style={{ margin: "0px", height: "36px", paddingTop: "0px", paddingBottom: "0px" }}>
                    {username ? <UserLoggedIn /> : <UserLoggedOff />}
                </ListItemText>
            </ListItem>
        ) : (
            <ListItem disablePadding sx={{ display: "block" }}>
                <StyledListItemButton
                    sx={{
                        height: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        <PersonIcon />
                    </ListItemIcon>
                </StyledListItemButton>
            </ListItem>
        )
    }
    */

    function UserLoggedIn() {
        return (
            <Grid textAlign="center">
                <Button type="button" onClick={() => history.push(`/users/${username}`)}>
                    {username}
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        logout()
                        goHome()
                        window.location.reload()
                    }}
                >
                    LOG OUT
                </Button>
            </Grid>
        )
    }

    function UserLoggedOff() {
        return (
            <Grid textAlign="center">
                <Button type="button" onClick={() => setOpenLogin(true)}>
                    LOG IN
                </Button>

                <Button type="button" onClick={() => setOpenSignup(true)}>
                    SIGN UP
                </Button>
            </Grid>
        )
    }

    function AppTheme() {
        return (
            <Menu
                id="App Theme Menu"
                open={themePickerState.open}
                anchorEl={themePickerState.anchorEl}
                onClose={closeThemePicker}
            >
                {actions.map((action) => (
                    <MenuItem
                        key={action.name}
                        onClick={() => {
                            props.setTheme(action.value)
                            closeThemePicker()
                        }}
                    >
                        {action.icon}
                        {action.name}
                    </MenuItem>
                ))}
            </Menu>
        )
    }
}
