import { firebaseAuthPopup } from "../auth/auth"
import { SIGNUP_URL } from "../common/constants"
import { PlaylistInfo, UserInfo } from "../common/dto"
import firebase, { googleAuthProvider } from "../config/firebase"
import { fetchApiPOST } from "../integrations/tabuation/apiFetcher"
import { getUserPlaylists } from "../integrations/tabuation/playlists"
import { createUser, getSelfInfo } from "../integrations/tabuation/users"

export interface UserSession {
    idToken: string
    userInfo?: UserInfo // TODO does it need a timeout?
    playlists?: PlaylistInfo[] // TODO timeout after X time???
}

export const googleLogIn = (): Promise<UserSession> => loginPopup(googleAuthProvider)
export const googleSignUp = (): Promise<UserSession> => signUpPopup(googleAuthProvider)

const loginPopup = async (authProvider: firebase.auth.AuthProvider): Promise<UserSession> => {
    const tokenId = await firebaseAuthPopup(authProvider).then(getAuthTokenId)
    try {
        return await getApiUser(tokenId)
    } catch (err: any) {
        console.log("error caught while authenticating with firebase auth " + JSON.stringify(err, null, 4))

        if (err.type && err.type.includes("tokenNotFound")) {
            console.log("Created user in firebase but not found in backend, creating a new user" + err)
            return createApiUser(tokenId).catch(handleFirebaseAuthError)
        } else {
            throw err
        }
    }
}

const signUpPopup = (authProvider: firebase.auth.AuthProvider): Promise<UserSession> => {
    return firebaseAuthPopup(authProvider).then(getAuthTokenId).then(createApiUser).catch(handleFirebaseAuthError)
}

/*
export const credentialsSignIn = (email: string, password: string): Promise<string> => {
    return firebaseEmailPasswordSignIn(email, password).then(getAuthTokenId).catch(handleFirebaseAuthError)
}

export const credentialsLogIn = (email: string, password: string): Promise<string> => {
    return firebaseEmailPasswordLogIn(email, password).then(getAuthTokenId).catch(handleFirebaseAuthError)
}
*/

const getAuthTokenId = (): Promise<string> => {
    // console.log("Get auth token id")
    return requireFirebaseCurrentUser().getIdToken()
}

const getApiUser = (idToken: string): Promise<UserSession> => {
    // console.log("get api user with idToken: " + idToken)
    return getSelfInfo(idToken).then((userInfo: UserInfo) => login(idToken, userInfo))
}

const createApiUser = (idToken: string): Promise<UserSession> => {
    // console.log("create api user with idToken: " + idToken)
    return createUser(idToken).then((userInfo: UserInfo) => login(idToken, userInfo))
}

const handleFirebaseAuthError = (error: any) => {
    console.log("error caught while authenticating with firebase auth " + error)
    throw error
}

export const setUserSession = (session: UserSession): void => {
    localStorage.setItem("UserSession", JSON.stringify(session, null, 4))
}

export const getUserSession = (): UserSession | undefined => {
    const userInfo = localStorage.getItem("UserSession")
    if (userInfo) {
        try {
            return JSON.parse(userInfo)
        } catch {
            return undefined
        }
    }
    return undefined
}

export interface PostUserDto {
    username: string
    password: string
    email: string
    displayName: string
    accessToken?: string
    pictureUrl?: string
}

export const createLocalAuthUser = (dto: PostUserDto): Promise<UserInfo> => {
    return fetchApiPOST(SIGNUP_URL, dto).then((res) => {
        if (!res.data || !res.data.id || !res.data.username) {
            throw Error("UserInfo was not returned")
        }
        return res.data as UserInfo
    })
}

export const requireFirebaseCurrentUser = (): firebase.User => {
    const currentUser = getFirebaseCurrentUser()
    if (currentUser) {
        return currentUser
    } else {
        throw Error("Firebase did not return any signed in user")
    }
}

export const getFirebaseCurrentUser = (): firebase.User | null => {
    return firebase.auth().currentUser
}

function getCurrentUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            unsubscribe()
            resolve(user)
        }, reject)
    })
}

export const getCurrentUserToken = (): Promise<string | undefined> => {
    return getCurrentUser()
        .then((user) => user?.getIdToken())
        .catch((reason) => {
            alert(`could not get current user, logging out. Reason=${reason}`)
            logout()
            return Promise.reject(reason)
        })
}

export const logout = () => {
    localStorage.removeItem("UserSession")
    firebase.auth().signOut()
}

export const login = (idToken: string, userInfo: UserInfo) => {
    const session: UserSession = { idToken: idToken, userInfo: userInfo }
    console.log("saving user session")
    setUserSession(session)
    return session
}

// Data management

export const getSessionPlaylists = (): Promise<PlaylistInfo[] | undefined> => {
    const session = getUserSession()

    if (session?.playlists) {
        return Promise.resolve(session?.playlists)
    } else if (session) {
        return getAndUpdateSessionPlaylists(session)
    } else {
        return Promise.resolve(undefined)
    }
}

export const updateSessionPlaylists = (): Promise<PlaylistInfo[] | undefined> => {
    const session = getUserSession()
    return session ? getAndUpdateSessionPlaylists(session) : Promise.resolve(undefined)
}

const getAndUpdateSessionPlaylists = (session: UserSession): Promise<PlaylistInfo[] | undefined> => {
    if (session?.userInfo?.username) {
        return getUserPlaylists(session.userInfo.username).then((playlists) => {
            session.playlists = playlists
            return playlists
        })
    } else {
        return Promise.resolve(undefined)
    }
}
