import { Alert, AlertColor, Grid } from "@mui/material"
import React, { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import { SearchForm } from "../base/SearchForm"
import { DEFAULT_PAGE_SIZE, DRAWER_SIZE_PX, USERS_URL } from "../common/constants"
import { UserInfo } from "../common/dto"
import { fetchAPI } from "../integrations/tabuation/apiFetcher"
import { ApiResponse } from "../integrations/tabuation/dto"
import { UserCardGrid } from "./UserCardGrid"

interface State {
    loading: boolean
    message: string
    severity: AlertColor
    userList?: UserInfo[]
}

const initialState: State = { loading: true, message: "loading...", severity: "info" }

export default function SearchUsersPage(): JSX.Element {
    const [state, setState] = useState(initialState)
    const { loading, message, severity, userList } = state

    const history = useHistory()

    const handleSubmit = (ev: FormEvent<HTMLElement>, username: string) => {
        ev.preventDefault()
        history.push("/users/" + username)
    }

    React.useEffect(() => {
        console.log(`use effect called with loading: ${loading}`)
        if (loading) {
            fetchUsers(`${USERS_URL}?page=0&size=${DEFAULT_PAGE_SIZE}`)
        }
    }, [])

    const fetchUsers = async (url: string) => {
        console.log("fetching users")
        fetchAPI(url).then(fillData).catch(handleError)
    }

    return (
        <div style={{ paddingLeft: DRAWER_SIZE_PX }}>
            <div style={{ padding: "24px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <SearchForm
                        label={"Search People"}
                        placeholder={"Username"}
                        onSubmit={(event, username) => handleSubmit(event, username)}
                    />
                </div>
                {userList && (
                    <Grid>
                        <h3>User List</h3>
                        <UserCardGrid userList={userList} />
                    </Grid>
                )}
                <div>
                    <Alert severity={severity}>{message}</Alert>
                </div>
            </div>
        </div>
    )

    function fillData(res: ApiResponse) {
        const userInfoList = res?.data?._embedded?.userInfoList
        if (userInfoList === undefined) throw Error("no data")

        setState({
            loading: false,
            message: "Successful",
            severity: "info",
            userList: userInfoList,
        })
    }

    function handleError(err: Error) {
        setState({ ...state, loading: false, message: err.message, severity: "error" })
    }
}
