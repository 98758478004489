import AddIcon from "@mui/icons-material/Add"
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    TextField,
} from "@mui/material"
import React, { useState } from "react"
import { useSnack } from "../base/Snackbar"
import { DEFAULT_SONG_THUMBNAIL_URL } from "../common/constants"
import { PlaylistInfo, TrackInfo } from "../common/dto"
import { addExistingTrackToPlaylist, createPlaylist } from "../integrations/tabuation/playlists"
import EditTrackDialog from "../Player/MediaPlayerV3/Components/EditTrackDialog"

interface Props {
    anchorEl: HTMLElement | null
    handleClose: () => void
    track: TrackInfo
    handleEditSuccess: (updatedTrack: TrackInfo) => void
    playlists: PlaylistInfo[] | undefined
    updatePlaylists: () => void
}

export function TrackOptions(props: Props): JSX.Element {
    const { anchorEl, handleClose, track, handleEditSuccess, playlists, updatePlaylists } = props

    const [editOpen, setEditOpen] = React.useState(false)
    const [choosePlaylistOpen, setChoosePlaylistOpen] = React.useState<boolean>(false)

    const { snackSuccess, snackError } = useSnack()
    return (
        <div>
            <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={handleClose}>
                {playlists ? (
                    <>
                        <MenuItem onClick={() => setChoosePlaylistOpen(true)}>Add to a Playlist...</MenuItem>
                        <ChoosePlaylistModal
                            open={choosePlaylistOpen}
                            setOpen={setChoosePlaylistOpen}
                            playlists={playlists}
                        />
                    </>
                ) : (
                    <></>
                )}
                <MenuItem onClick={() => setEditOpen(true)}>Edit...</MenuItem>
            </Menu>
            <EditTrackDialog
                open={editOpen}
                track={track}
                handleSuccess={(updatedTrack) => {
                    handleEditSuccess(updatedTrack)
                    setEditOpen(false)
                }}
                handleClose={() => setEditOpen(false)}
            />
        </div>
    )

    function handleError(error: Error) {
        snackError(error.message)
    }

    function ChoosePlaylistModal(props: {
        open: boolean
        setOpen: (open: boolean) => void
        playlists: PlaylistInfo[]
    }): JSX.Element {
        const { open, setOpen, playlists } = props

        const [isEdit, setIsEdit] = useState(false)

        return (
            <Dialog id="choose-playlist-modal" open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add this track to:</DialogTitle>
                <DialogContent>
                    <MenuList>
                        {playlists.map((playlist, index) => {
                            return (
                                <MenuItem key={index} onClick={() => addToPlaylist(index, playlist.name, playlist.id)}>
                                    {playlist.name}
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </DialogContent>
                <NewPlaylistAction
                    isEdit={isEdit}
                    setIsEdit={(isEdit) => setIsEdit(isEdit)}
                    createPlaylist={(name) => createPlaylist(playlists.length, name, "New playlist id")}
                />
            </Dialog>
        )

        async function addToPlaylist(index: number, name: string, id: string) {
            snackSuccess(`Added new track to playlist ${index}: ${name} -> ${id}`)
            addTrackToPlaylist(id)
            setOpen(false)
        }
        async function createPlaylist(index: number, name: string, id: string) {
            snackSuccess(`Created playlist playlist ${index}: ${name} -> ${id}`)
            createPlaylistWithTrack()
            setOpen(false)
        }
    }

    function addTrackToPlaylist(playlistId: string) {
        addExistingTrackToPlaylist(track.id, playlistId)
            .then(() => snackSuccess(`Added track ${track.id} to playlist ${playlistId} successfully!`))
            .catch(handleError)
    }

    function createPlaylistWithTrack() {
        const thumbnailUrl = DEFAULT_SONG_THUMBNAIL_URL

        createPlaylist({
            name: track.title,
            public: false,
            description: "No description available",
            pictureUrl: thumbnailUrl,
            tracksIds: [track.id],
        })
            .then(() => snackSuccess("Playlist created!"))
            .then(() => updatePlaylists())
            .catch((err) => snackError(`An error happened while creating the playlist: ${err.message}`))
    }
}

const NewPlaylistAction: React.FC<{
    isEdit: boolean
    setIsEdit: (isEdit: boolean) => void
    createPlaylist: (name: string) => void
}> = ({ isEdit, setIsEdit, createPlaylist }) => {
    const [name, setName] = useState("")

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)
    }

    return (
        <React.Fragment>
            <Box style={{ padding: "8px" }}>
                {!isEdit ? (
                    <ListItem
                        button
                        onClick={() => {
                            setName("")
                            setIsEdit(true)
                        }}
                    >
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={"New playlist..."} />
                    </ListItem>
                ) : (
                    <Grid container>
                        <form>
                            <TextField label="Playlist Name" onChange={handleChange}></TextField>
                            <Button onClick={() => createPlaylist(name)}>Create</Button>
                        </form>
                    </Grid>
                )}
            </Box>
        </React.Fragment>
    )
}
