import { MusicNote, QueueMusic } from "@mui/icons-material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Avatar, IconButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import React from "react"
import { StyledListItemButton } from "../../../common/components/StyledListItemButton"
import { PlaylistInfo, TrackInfo } from "../../../common/dto"
import { TrackOptions } from "../../../tracks/TrackOptions"

export const defaultAvatar = "https://static.thenounproject.com/png/55431-200.png"

interface Props {
    track: TrackInfo
    selected: boolean
    handleClick: () => void
    canEdit: boolean
    handleUpdate?: (updated: TrackInfo) => void
    playlists: PlaylistInfo[] | undefined
    updatePlaylists: () => void
}

export function TrackListItem(props: Props): JSX.Element {
    const { track, selected, handleClick, canEdit, handleUpdate, playlists, updatePlaylists } = props

    const avatarUrl = track.pictureUrl ? track.pictureUrl : defaultAvatar

    const hasAudio: boolean = track.youtubeLink && track.spotifyLink && track.soundcloudLink ? true : false
    const hasTabs = track.tabs && track.tabs.length > 0

    const audioIconOpacity = hasAudio ? 1 : 0
    const tabsIconOpacity = hasTabs ? 1 : 0

    const [optionsAnchorEl, setOptionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const optionsHandleClick = (event: React.MouseEvent<HTMLElement>) => setOptionsAnchorEl(event.currentTarget)

    return (
        <StyledListItemButton selected={selected} onClick={handleClick}>
            <Avatar variant="square" src={avatarUrl} style={{ height: "46px", width: "46px", marginRight: "16px" }} />
            <ListItemText
                primary={track.title}
                secondary={track.artists}
                style={{ whiteSpace: "nowrap", overflowX: "hidden", textOverflow: "ellipsis" }}
            />
            <ListItemSecondaryAction>
                <MusicNote style={{ opacity: audioIconOpacity }} />
                <QueueMusic style={{ opacity: tabsIconOpacity }} />
                {canEdit && (
                    <>
                        <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => optionsHandleClick(event)}>
                            <MoreVertIcon />
                        </IconButton>
                        <TrackOptions
                            anchorEl={optionsAnchorEl}
                            handleClose={() => setOptionsAnchorEl(null)}
                            track={track}
                            handleEditSuccess={handleSuccess}
                            playlists={playlists}
                            updatePlaylists={updatePlaylists}
                        ></TrackOptions>
                    </>
                )}
            </ListItemSecondaryAction>
        </StyledListItemButton>
    )

    function handleSuccess(updated: TrackInfo) {
        if (handleUpdate) handleUpdate(updated)
    }
}
