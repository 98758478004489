import { Grid } from "@mui/material"
import React from "react"
import { DRAWER_SIZE } from "../common/constants"

export const AbsolutePosPage: React.FC = () => {
    return (
        <React.Fragment>
            <Grid container style={{ height: "90vh", paddingLeft: `${DRAWER_SIZE}px` }}>
                <Grid direction="column" item xs={5} md={4}>
                    <span style={{ height: "100%", width: "100%", backgroundColor: "red" }}></span>
                </Grid>
                <Grid direction="column" item xs={7} md={8}>
                    <span style={{ height: "100%", width: "100%", backgroundColor: "blue" }}></span>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
