import { Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material"
import React, { useState } from "react"
import { SearchType } from "./dto"

export interface Props {
    placeholder: string
    handleSubmit: (searchType: SearchType, searchTerm: string, mocked: boolean) => void
}

export default function YoutubeSearchForm(props: Props): JSX.Element {
    /*
    const [mocked, setMocked] = useState<boolean>(false)
    const handleMockedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMocked(event.target.checked)
    }
    */

    const [searchTerm, setSearchTerm] = useState<string>(props.placeholder)
    const handlePlaylistIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setSearchTerm(event.target.value)
    }

    const [searchType, setSearchType] = useState<SearchType>(SearchType.TRACK)
    const handleChange = (event: SelectChangeEvent<SearchType>) => {
        setSearchType(event.target.value as SearchType)
    }

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        props.handleSubmit(searchType, searchTerm, false)
    }

    return (
        <Paper
            component="form"
            sx={{
                p: "8px 16px",
                display: "flex",
                alignItems: "center",
                "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSearchSubmit}
        >
            <FormControl variant="filled">
                <InputLabel id="search-type-select">Search</InputLabel>
                <Select id="search-type-select" value={searchType} onChange={handleChange} style={{ width: "150px" }}>
                    {Object.values(SearchType)
                        .filter((x) => isNaN(parseInt(x)))
                        .map((type) => (
                            <MenuItem value={type} key={type}>
                                {type}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <TextField
                id="standard-basic"
                label="search term"
                value={searchTerm}
                onChange={handlePlaylistIdChange}
                style={{ width: "300px" }}
            />
            {/** 
            <FormControlLabel control={<Checkbox checked={mocked} onChange={handleMockedChange} />} label="mocked" />
            */}
            <Button type="submit" variant="contained">
                Submit
            </Button>
        </Paper>
    )
}
