import { Button, Dialog, DialogTitle, Grid, Paper, styled } from "@mui/material"
import { default as React, useContext } from "react"
import { ThemeContext } from "../App"
import { ThemeType } from "../common/enum"
import GoogleSignInButtonDark from "../resources/buttons/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
import GoogleSignInButtonLight from "../resources/buttons/google_signin_buttons/web/2x/btn_google_signin_light_focus_web@2x.png"
import { googleSignUp } from "./session"

export interface Props {
    handleClose: () => void
}

const Main = styled(Paper)(({ theme }) => ({
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + parseInt(theme.spacing(2)) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
    },
    padding: theme.spacing(2),
}))

export default function SignUpDialog(props: Props): JSX.Element {
    const themeType: ThemeType = useContext(ThemeContext)
    const GoogleSignInButton = themeType == ThemeType.Dark ? GoogleSignInButtonDark : GoogleSignInButtonLight

    return (
        <Dialog open={true} onClose={props.handleClose}>
            <DialogTitle>Sign up</DialogTitle>
            <Main>
                <Grid
                    container
                    spacing={3}
                    style={{ justifyContent: "center", paddingTop: "16px", paddingBottom: "8px" }}
                >
                    <Grid container style={{ justifyContent: "center" }}>
                        <Button style={{ padding: "0px" }} onClick={handleGoogleSignIn}>
                            <img src={GoogleSignInButton} style={{ height: "45px", resize: "both" }} />
                        </Button>
                    </Grid>
                    <Button onClick={props.handleClose} fullWidth>
                        Close
                    </Button>
                </Grid>
            </Main>
        </Dialog>
    )

    function handleGoogleSignIn() {
        googleSignUp().then(handleLoginSuccess).catch(handleLoginError)
    }

    function handleLoginSuccess() {
        window.alert("Sign in successful!")
        setTimeout(() => props.handleClose(), 3000)
    }

    function handleLoginError(err: any) {
        if (err.type) {
            const type = err.type as string
            alert(type.includes("emailTaken"))

            if (type.includes("emailTaken")) {
                alert("Email is already taken, please use a different one")
            } else {
                alert("Api error response caught: type = " + type)
            }
        } else {
            alert("Generic error found: " + err)
        }
    }
}
