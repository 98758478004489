import { Grid } from "@mui/material"
import React from "react"
import { PlaylistInfo } from "../common/dto"
import { PlaylistCard } from "./PlaylistCard"

export const PlaylistCardGrid: React.FC<{
    playlists: PlaylistInfo[]
    canEdit: boolean
    handleUpdate: (updated: PlaylistInfo) => void
    handleDelete: (playlistId: string) => void
}> = ({ playlists, canEdit, handleUpdate, handleDelete }) => {
    return (
        <Grid container>
            <Grid container spacing={1} style={{ marginTop: "10px", marginBottom: "10px" }}>
                {playlists.map((playlist) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={"card_" + playlist.id}>
                        <PlaylistCard
                            playlist={playlist}
                            canEdit={canEdit}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}
