import { getCurrentUserToken } from "../../base/session"
import { BASE_URL, SIGNUP_URL, USERS_URL } from "../../common/constants"
import { Tab, UpdateUserDto, UserDetails, UserInfo } from "./../../common/dto"
import { fetchApiGET, fetchApiPATCH, fetchApiPOST } from "./apiFetcher"
import { ApiResponse, CreateTabDto } from "./dto"

export const getUserInfo = async (username: string): Promise<UserInfo> => {
    return getCurrentUserToken()
        .then((token) => fetchApiGET(`${USERS_URL}/${username}`, token))
        .then((res) => res.data as UserInfo)
}

export const getUserDetails = async (username: string): Promise<UserDetails> => {
    return getCurrentUserToken()
        .then((token) => fetchApiGET(`${USERS_URL}/${username}/details`, token))
        .then((res) => res.data as UserDetails)
}

export const getSelfInfo = async (idToken?: string): Promise<UserInfo> => {
    const token = idToken ? idToken : await getCurrentUserToken()
    if (!token) throw Error("Tried to make a 'get self user' call without auth token")

    return fetchApiGET(`${USERS_URL}/self`, token).then((res) => res.data as UserInfo)
}

export const createUser = async (idToken?: string): Promise<UserInfo> => {
    const token = idToken ? idToken : await getCurrentUserToken()
    if (!token) throw Error("Tried to make a 'create user' call without auth token")

    return fetchApiPOST(SIGNUP_URL, null, idToken).then((res) => res.data as UserInfo)
}

export const updateClient = async (username: string, updateUser: UpdateUserDto): Promise<UserDetails> => {
    return getCurrentUserToken()
        .then((token) => fetchApiPATCH(`${USERS_URL}/${username}`, updateUser, token))
        .then((res) => res.data as UserDetails)
}

export const getTrackTabs = async (trackId: string): Promise<Tab[]> => {
    const url = `${BASE_URL}/tracks/${trackId}/tabs`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res) => (res.data._embedded?.tabList ? res.data._embedded?.tabList : []))
        .catch((err: Error) => {
            throw Error(`An error happened while fetching the tracks's tabs: ${err.message}`)
        })
}

export const addTabToTrack = async (trackId: string, requestDto: CreateTabDto): Promise<Tab> => {
    const url = `${BASE_URL}/tracks/${trackId}/tabs`

    const token = getCurrentUserToken()
    if (!token) throw Error("You need to be signed in to 'add tab to track'")

    return fetchApiPOST(url, requestDto)
        .then((res: ApiResponse) => res.data as Tab)
        .catch((err: Error) => {
            throw Error(`An error happened while adding a new tab to the track ${trackId}: ${err.message}`)
        })
}
