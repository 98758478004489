import {
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    TextareaAutosize,
    Typography,
} from "@mui/material"
import React from "react"
import { processTab } from "../../../base/utils"
import { TabWithBreakpoints } from "./TabWithBreakpoints"

export const TabEditor: React.FC<{
    hasTab: boolean
    editing: boolean
    tabText?: string
    setTempTab: (newTabText: string) => void
    timestamp: string
}> = ({ hasTab, editing, tabText, setTempTab, timestamp }) => {
    const [withBreakpoints, setWithBreakpoints] = React.useState<boolean>(true)
    const handleWithBreakpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWithBreakpoints(event.target.checked)
    }

    console.log(`Tab Editor render, timestamp=${timestamp}`)

    const tabData = withBreakpoints && tabText ? processTab(tabText) : undefined

    const breakpoints = ["start", ...(tabData ? tabData.timestamps : [])]

    const [breakpoint, setBreakpoint] = React.useState<string>("start")

    return (
        <React.Fragment>
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "100%", height: "40px", paddingLeft: "8px" }}>
                    <div style={{ display: "flex" }}>
                        <FormControlLabel
                            control={<Switch defaultChecked onChange={handleWithBreakpointsChange} />}
                            label="Breakpoints"
                        />
                        {hasTab && withBreakpoints && tabData ? (
                            <Grid container>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <RadioGroup value={breakpoint} onChange={handleChange} row>
                                            {breakpoints.map((breakpoint, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={breakpoint.toString()}
                                                    control={<Radio />}
                                                    label={breakpoint.toString()}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <Divider />
                <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
                    {hasTab ? (
                        editing ? (
                            <EditableTabPage tabText={tabText ? tabText : ""} setTempTab={setTempTab} />
                        ) : tabText ? (
                            withBreakpoints && tabData ? (
                                <TabWithBreakpoints
                                    tabData={tabData}
                                    breakpoint={breakpoint}
                                    setBreakpoint={setBreakpoint}
                                    timestamp={timestamp}
                                />
                            ) : (
                                <TabPage tabText={tabText} />
                            )
                        ) : (
                            <TextPage text="No tab text" />
                        )
                    ) : (
                        <TextPage text="No tab selected" />
                    )}
                </div>
            </div>
        </React.Fragment>
    )

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const timestamp = (event.target as HTMLInputElement).value
        setBreakpoint(timestamp)
    }
}

const TextPage: React.FC<{ text: string }> = ({ text }) => {
    return (
        <React.Fragment>
            <p
                style={{
                    paddingTop: "16px",
                    textAlign: "center",
                    height: "100%",
                    width: "100%",
                    margin: "0px",
                }}
            >
                {text}
            </p>
        </React.Fragment>
    )
}

const TabPage: React.FC<{ tabText: string }> = ({ tabText }) => {
    return (
        <React.Fragment>
            <Typography
                variant="h6"
                style={{
                    textAlign: "left",
                    height: "100%",
                    width: "100%",
                    margin: "0px",
                    padding: "8px",
                    overflowWrap: "anywhere",
                    whiteSpace: "pre-line",
                    overflow: "scroll",
                    overflowY: "scroll",
                }}
            >
                {tabText}
            </Typography>
        </React.Fragment>
    )
}

const EditableTabPage: React.FC<{ tabText: string; setTempTab: (newTabText: string) => void }> = ({
    tabText,
    setTempTab,
}) => {
    return (
        <React.Fragment>
            <TextareaAutosize
                defaultValue={tabText ? tabText : ""}
                style={{
                    height: "100%",
                    width: "100%",
                    padding: "8px",
                    overflow: "scroll",
                    overflowY: "scroll",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                }}
                onChange={(event: any) => setTempTab(event.target.value)}
            />
        </React.Fragment>
    )
}
