import { Avatar, Button, Divider, Grid, Link, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { getUserSession } from "../base/session"
import { useSnack } from "../base/Snackbar"
import { DEFAULT_PROFILE_PIC_URL, DRAWER_SIZE_PX } from "../common/constants"
import { PlaylistInfo, UserInfo } from "../common/dto"
import { ListHeader } from "../common/ListHeader"
import { getUserPlaylists } from "../integrations/tabuation/playlists"
import { getUserInfo } from "../integrations/tabuation/users"
import { PlaylistCardGrid } from "./PlaylistCardGrid"

const CustomDivider = styled(Divider)(({ theme }) => ({ margin: theme.spacing(2, 0) }))

export const UserPlaylistsPage: React.FC<RouteComponentProps<{ username?: string }>> = ({ match }) => {
    const username: string | undefined | null = match.params.username
        ? match.params.username
        : getUserSession()?.userInfo?.username

    const isOwnUser: boolean = username != undefined && username === getUserSession()?.userInfo?.username

    const [userInfo, setUserInfo] = useState<UserInfo | undefined>()
    const [playlists, setPlaylists] = useState<PlaylistInfo[] | undefined>()

    const { snackError } = useSnack()

    useEffect(() => {
        if (username) {
            getUserInfo(username).then(setUserInfo).catch(handleGetUserInfoError)

            getUserPlaylists(username).then(setPlaylists).catch(handleGetPlaylistsError)
        } else {
            snackError("no username??")
        }
    }, [username])

    const history = useHistory()
    return (
        <div style={{ paddingLeft: DRAWER_SIZE_PX }}>
            <div style={{ padding: "24px" }}>
                <Grid container>
                    <ListHeader
                        header={userInfo?.username ? userInfo?.username : "unknown"}
                        to={"/users/" + username}
                    ></ListHeader>
                    <h2 style={{ whiteSpace: "pre-wrap" }}> | </h2>
                    <ListHeader header="Playlists" />
                </Grid>
                {playlists && playlists.length > 0 ? (
                    <PlaylistCardGrid
                        playlists={playlists}
                        canEdit={isOwnUser}
                        handleUpdate={updatePlaylist}
                        handleDelete={handleDelete}
                    />
                ) : (
                    <div>
                        {isOwnUser ? <p>No playlists saved</p> : <p>No playlists found</p>}
                        {isOwnUser && (
                            <Link component="button" variant="body2" onClick={() => history.push("/search/youtube")}>
                                Search for new music on youtube...
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    )

    function updatePlaylist(updated: PlaylistInfo): void {
        if (playlists) setPlaylists([updated, ...playlists.filter((tab) => tab.id !== updated.id)])
    }

    function handleDelete(playlistId: string): void {
        if (playlists) setPlaylists(playlists.filter((playlist) => playlist.id !== playlistId))
    }

    function handleGetPlaylistsError(err: Error): void {
        snackError(`Couldn't update playlists: ${err.message}`)
    }

    function handleGetUserInfoError(err: any): void {
        const message = err.type && err.type.includes("UserNotFound") ? "No user with that username found" : err.message
        snackError(message)
    }
}

const UserHeader: React.FC<{ userInfo: UserInfo; isOwnUser: boolean }> = ({ userInfo, isOwnUser }) => {
    const { username, pictureUrl } = userInfo
    const avatar = pictureUrl ? pictureUrl : DEFAULT_PROFILE_PIC_URL

    const history = useHistory()
    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar style={{ width: 100, height: 100, marginRight: "16px" }} alt={username} src={avatar} />
                <Typography color="textSecondary" gutterBottom style={{ fontSize: "28px", fontWeight: "bold" }}>
                    {username}
                </Typography>
            </div>
            {isOwnUser && (
                <div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                    <Button onClick={() => history.push(`/users/${username}/settings`)}>
                        <Typography>Settings</Typography>
                    </Button>
                </div>
            )}
        </div>
    )
}
