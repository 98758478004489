import FavoriteIcon from "@mui/icons-material/FavoriteOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVertOutlined"
import ShareIcon from "@mui/icons-material/ShareOutlined"
import { CardActionArea, styled } from "@mui/material"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import IconButton from "@mui/material/IconButton"
import React, { useState } from "react"
import { DEFAULT_SONG_THUMBNAIL_URL } from "../common/constants"
import { PlaylistInfo } from "../common/dto"
import { getBestThumbnailOrDefault, YoutubeSearchResult } from "../integrations/youtube/dto"
import { YoutubeVideoOptions } from "./YoutubeVideoOptions"

const StyledMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const DisplayedDiv = styled("div")(({ theme }) => ({ display: "block" }))
const NotDisplayedDiv = styled("div")(({ theme }) => ({ display: "none" }))

interface Props {
    video: YoutubeSearchResult
    onClick?: () => void
    playlists?: PlaylistInfo[]
    updatePlaylists?: () => void
}

export default function YoutubeVideoCard(props: Props): JSX.Element {
    const { video, onClick, playlists, updatePlaylists } = props
    const [hover, setHover] = useState<boolean>(false)

    const [trackOptionsAnchorEl, setTrackOptionsAnchorEl] = React.useState<null | HTMLElement>(null)
    const trackOptionHandleClick = (event: React.MouseEvent<HTMLElement>) =>
        setTrackOptionsAnchorEl(event.currentTarget)

    const thumbnailUrl = getBestThumbnailOrDefault(video.snippet.thumbnails, DEFAULT_SONG_THUMBNAIL_URL)

    const hasMenu = playlists !== undefined && updatePlaylists !== undefined

    const HoverDiv = hover ? DisplayedDiv : NotDisplayedDiv
    return (
        <Card onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ maxWidth: 345 }}>
            <div style={{ position: "relative" }}>
                <CardActionArea onClick={onClick}>
                    <CardMedia image={thumbnailUrl} style={{ height: 0, paddingTop: "56.25%" }} />
                    <CardHeader
                        title={video.snippet.title}
                        subheader={video.snippet.channelTitle}
                        titleTypographyProps={{ variant: "h6" }}
                        subheaderTypographyProps={{ variant: "subtitle2" }}
                        style={{ width: "100%", padding: "8px" }}
                    />
                </CardActionArea>
                {hasMenu && (
                    <HoverDiv style={{ right: 0, top: 0, maxWidth: "48px", position: "absolute" }}>
                        <HoverIcons handleClick={trackOptionHandleClick} />
                    </HoverDiv>
                )}
            </div>
            {playlists && updatePlaylists && (
                <YoutubeVideoOptions
                    anchorEl={trackOptionsAnchorEl}
                    handleClose={() => setTrackOptionsAnchorEl(null)}
                    video={video}
                    playlists={playlists}
                    updatePlaylists={updatePlaylists}
                ></YoutubeVideoOptions>
            )}
        </Card>
    )
}

const HoverIcons = (props: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }) => {
    return (
        <div>
            <IconButton aria-label="options" onClick={props.handleClick}>
                <StyledMoreVertIcon />
            </IconButton>
            <IconButton aria-label="add to favorites">
                <StyledFavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
                <StyledShareIcon />
            </IconButton>
        </div>
    )
}
