import { Avatar, Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import React from "react"
import { DEFAULT_PROFILE_PIC_URL } from "../common/constants"
import { UserInfo } from "../common/dto"

interface Props {
    userInfo: UserInfo
    goToLink?: (username: string) => void
}

export const UserInfoCard = ({ userInfo, goToLink }: Props): JSX.Element => {
    const { username, pictureUrl } = userInfo

    const avatar = pictureUrl ? pictureUrl : DEFAULT_PROFILE_PIC_URL

    return (
        <Card>
            <CardContent>
                <Avatar alt={username} src={avatar} />
                <Typography color="textSecondary" gutterBottom>
                    {username}
                </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: "flex-end" }}>
                {goToLink && (
                    <Button onClick={() => goToLink(username)} className="float-right">
                        Go To
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}
