export const BASE_URL =
    process.env.NODE_ENV === "production" ? "https://tabuation-server.herokuapp.com" : "http://localhost:8080"

export const LOGIN_URL = `${BASE_URL}/login`
export const SIGNUP_URL = `${BASE_URL}/users`
export const USERS_URL = `${BASE_URL}/users`
export const TRACKS_URL = `${BASE_URL}/project`

export const USER_DEFAULT = "firstUser"
export const PROJECT_DEFAULT = "p001"

export const DEFAULT_PAGE_NUM = 0
export const DEFAULT_PAGE_SIZE = 100

export const DRAWER_SIZE = 64
export const DRAWER_SIZE_PX = `${DRAWER_SIZE}px`

export const DEFAULT_SONG_THUMBNAIL_URL = "https://m.media-amazon.com/images/I/71UADtAwr1L._SS500_.jpg"

export const DEFAULT_PROFILE_PIC_URL =
    "https://steamuserimages-a.akamaihd.net/ugc/786371856221183225/2F04B32CA10AD1ADBC01CE5D4DC6F7AF0E96AE6C/?imw=512&imh=512&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true"
