import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useContext, useState } from "react"
import { ThemeContext } from "../App"
import { ThemeType } from "../common/enum"
import GoogleSignInButtonDark from "../resources/buttons/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png"
import GoogleSignInButtonLight from "../resources/buttons/google_signin_buttons/web/2x/btn_google_signin_light_focus_web@2x.png"
import { googleLogIn } from "./session"

export interface Props {
    handleClose: () => void
}

export const LoginDialog: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
    const [state, setState] = useState({ username: "", password: "" })

    const themeType: ThemeType = useContext(ThemeContext)
    const GoogleSignInButton = themeType == ThemeType.Dark ? GoogleSignInButtonDark : GoogleSignInButtonLight

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>LOGIN</DialogTitle>
            <DialogContent style={{ minWidth: "400px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button onClick={handleGoogleSignIn}>
                        <img src={GoogleSignInButton} style={{ height: "45px", resize: "both" }} />
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} fullWidth>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

    function handleGoogleSignIn() {
        googleLogIn()
            .then(() => {
                window.location.reload()
                handleClose()
            })
            // If the backend does not recognize, sign up user
            .catch((err) => {
                handleClose()
                console.log("error caught: " + err)
            })
    }
}
