/**
 * @returns the current time in milliseconds in number format
 */
export const now = (): number => new Date().getTime()

export const processTabSearchString = (title: string): string => {
    let s = title
    TAB_SEARCH_STRINGS_TO_REMOVE.forEach((toRemove) => (s = s.replace(toRemove, "")))
    return s.trim()
}

export const TAB_SEARCH_STRINGS_TO_REMOVE = ["-", "(Official Video)", "(Official Music Video)"]
