import FavoriteIcon from "@mui/icons-material/FavoriteOutlined"
import MoreVertIcon from "@mui/icons-material/MoreVertOutlined"
import ShareIcon from "@mui/icons-material/ShareOutlined"
import { CardActionArea, styled, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import IconButton from "@mui/material/IconButton"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { PlaylistInfo } from "../common/dto"
import { PlaylistEditDialog } from "./PlaylistEditDialog"

const StyledMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
    stroke: theme.palette.background.default,
    strokeWidth: 1,
}))

const DisplayedDiv = styled("div")(({ theme }) => ({ display: "block" }))
const NotDisplayedDiv = styled("div")(({ theme }) => ({ display: "none" }))

export const PlaylistCard: React.FC<{
    playlist: PlaylistInfo
    canEdit: boolean
    handleUpdate?: (updatedPlaylist: PlaylistInfo) => void
    handleDelete?: (playlistId: string) => void
}> = ({ playlist, canEdit, handleUpdate, handleDelete }) => {
    const [hover, setHover] = useState<boolean>(false)
    const HoverDiv = hover ? DisplayedDiv : NotDisplayedDiv

    const [openEdit, setOpenEdit] = React.useState(false)

    const history = useHistory()
    return (
        <Card
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ maxWidth: 200, position: "relative" }}
        >
            <HoverDiv style={{ maxWidth: "48px", position: "absolute", zIndex: 1, right: 0 }}>
                <HoverIcons handleClick={() => setOpenEdit(true)} />
            </HoverDiv>
            {canEdit && handleUpdate && handleDelete && (
                <PlaylistEditDialog
                    open={openEdit}
                    playlist={playlist}
                    handleClose={() => setOpenEdit(false)}
                    handleSuccess={handleUpdate}
                    handleDelete={() => handleDelete(playlist.id)}
                />
            )}

            <CardActionArea onClick={() => history.push(`/player/${playlist.id}`)}>
                <CardMedia
                    image={playlist.pictureUrl}
                    title={playlist.name}
                    style={{ height: "0px", paddingTop: "100%" }}
                />
            </CardActionArea>

            <CardHeader
                title={
                    <Typography noWrap gutterBottom variant="h6">
                        {playlist.name}
                    </Typography>
                }
                subheader={
                    <Typography noWrap gutterBottom variant="subtitle2">
                        {playlist.ownerUsername}
                    </Typography>
                }
                style={{ width: "100%", padding: "8px", overflow: "hidden" }}
            />
        </Card>
    )
}

const HoverIcons = (props: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }) => {
    return (
        <div>
            <IconButton aria-label="options" onClick={props.handleClick}>
                <StyledMoreVertIcon />
            </IconButton>
            <IconButton aria-label="add to favorites">
                <StyledFavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
                <StyledShareIcon />
            </IconButton>
        </div>
    )
}
