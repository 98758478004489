import { createTheme } from "@mui/material"

export const COLORS_WHITE = "#fff"
export const COLORS_ORANGE = "#ed6c02"
export const COLORS_ORANGE_LIGHT = "#ed6c02"
export const COLORS_ORANGE_DARK = "#e65100"
export const COLORS_ORANGE_PASTEL = "#ffb300"

export const COLORS_PRIMARY = "#f4a261"
export const COLORS_PRIMARY_DARK = "#dd9057"
export const COLORS_BACKGROUND_10 = "#000814"
export const COLORS_BACKGROUND_20 = "#0d1b2a"

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: COLORS_PRIMARY,
        },
    },
    typography: {
        fontFamily: "'Roboto Mono', monospace",
        /*
        h2: {
            fontWeight: "bold",
        },
        */
        h6: {
            fontSize: 13,
            //fontWeight: "bold",
        },
    },
})

const { palette: darkPalette } = createTheme()

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: COLORS_PRIMARY,
        },
        background: {
            paper: COLORS_BACKGROUND_20,
        },
    },

    typography: {
        fontFamily: "'Roboto Mono', monospace",
        /*
        h2: {
            fontWeight: "bold",
        },
        */
        h6: {
            fontSize: 13,
            //fontWeight: "bold",
        },
    },
})
