import React from "react"
import { RouteComponentProps } from "react-router-dom"

const NoMatchPage = (props: RouteComponentProps): JSX.Element => (
    <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <h3>
                404: Page <code>{props.location.pathname}</code> not found
            </h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src="https://i.imgur.com/qhMbkGi.jpg" alt="new" />
        </div>
    </div>
)

export default NoMatchPage
