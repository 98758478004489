import { getCurrentUserToken, getUserSession } from "../../base/session"
import { BASE_URL } from "../../common/constants"
import { PlaylistInfo, TrackInfo } from "../../common/dto"
import { UpdatePlaylistDto } from "../../playlists/PlaylistEditDialog"
import { fetchApiDelete, fetchApiGET, fetchApiPATCH, fetchApiPOST } from "./apiFetcher"
import { CreatePlaylistDto, CreateTrackDto } from "./dto"

export const getPublicPlaylists = async (): Promise<PlaylistInfo[]> => {
    const url = `${BASE_URL}/playlists?page=0&size=100`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res: any) => {
            // alert(JSON.stringify(res.data, null, 4))

            if (res?.data?._embedded?.playlistList) {
                return res.data._embedded.playlistList as PlaylistInfo[]
            } else if (res?.data) {
                return []
            } else {
                // alert(JSON.stringify(res, null, 4))
                throw Error(`getPublicPlaylists response as badly formatted`)
            }
        })
        .catch((err: Error) => {
            throw Error(`An error happened while fetching public playlists: ${err.message}`)
        })
}

export const getUserPlaylists = async (username: string): Promise<PlaylistInfo[]> => {
    const url = `${BASE_URL}/users/${username}/playlists?page=0&size=100`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res: any) => {
            // alert(JSON.stringify(res.data, null, 4))

            if (res?.data?._embedded?.playlistList) {
                return res.data._embedded.playlistList as PlaylistInfo[]
            } else if (res?.data) {
                return []
            } else {
                // alert(JSON.stringify(res, null, 4))
                throw Error(`getUserPlaylists response as badly formatted`)
            }
        })
        .catch((err: Error) => {
            throw Error(`An error happened while fetching user playlists: ${err.message}`)
        })
}

export const createPlaylist = async (requestDto: CreatePlaylistDto): Promise<any> => {
    const username = getUserSession()?.userInfo?.username
    if (!username) throw Error("No session saved")

    const url = `${BASE_URL}/users/${username}/playlists`

    return getCurrentUserToken()
        .then((token) => fetchApiPOST(url, requestDto, token))
        .then((res: any) => {
            console.debug(`createPlaylist response: ${JSON.stringify(res, null, 4)}`)
            return "Playlist added!"
        })
        .catch((err: Error) => {
            throw Error(`An error happened while creating the playlist: ${err.message}`)
        })
}

export const getPlaylist = async (playlistId: string): Promise<PlaylistInfo> => {
    const url = `${BASE_URL}/playlists/${playlistId}`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res: any) => {
            // alert(JSON.stringify(res, null, 4))
            if (res?.data) {
                return res.data as PlaylistInfo
            } else {
                // alert(JSON.stringify(res, null, 4))
                throw Error(`getUserPlaylists response as badly formatted`)
            }
        })
        .catch((err: Error) => {
            // alert(JSON.stringify(err, null, 4))
            throw Error(`An error happened while fetching the playlist: ${err.message}`)
        })
}

export const updatePlaylist = async (playlistId: string, request: UpdatePlaylistDto): Promise<PlaylistInfo> => {
    const url = `${BASE_URL}/playlists/${playlistId}`

    // alert(`request: ${JSON.stringify(request, null, 4)}`)

    return getCurrentUserToken()
        .then((token) => fetchApiPATCH(url, request, token))
        .then((res: any) => {
            // alert(`response: ${JSON.stringify(res, null, 4)}`)
            if (res?.data) {
                return res.data as PlaylistInfo
            } else {
                throw Error(`updatePlaylist response as badly formatted`)
            }
        })
        .catch((err: Error) => {
            throw Error(`An error happened while updating the playlist: ${err.message}`)
        })
}

export const deletePlaylist = async (playlistId: string) => {
    const url = `${BASE_URL}/playlists/${playlistId}`

    // alert(`request: ${JSON.stringify(request, null, 4)}`)

    getCurrentUserToken()
        .then((token) => {
            if (token) return fetchApiDelete(url, token)
            else throw Error("No user logged in")
        })
        .catch((err: Error) => {
            throw Error(`An error happened while updating the playlist: ${err.message}`)
        })
}

/**
 * TODO this could be done without authentication for public tracklists
 */
export const getPlaylistTracks = async (playlistId: string): Promise<TrackInfo[]> => {
    const url = `${BASE_URL}/playlists/${playlistId}/tracks?page=0&size=100`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res: any) => {
            // console.debug(`getPlaylistTracks response: ${JSON.stringify(res, null, 4)}`)

            if (res?.data?._embedded?.trackList) {
                return res.data._embedded.trackList as TrackInfo[]
            } else if (res?.data?._links) {
                return []
            } else {
                throw Error(`getPlaylistTracks response as badly formatted: ${JSON.stringify(res, null, 4)}`)
            }
        })
        .catch((err: Error) => {
            throw Error(`An error happened while fetching playlist tracks: ${err.message}`)
        })
}

export const addNewTrackToPlaylist = async (requestDto: CreateTrackDto, playlistId: string): Promise<any> => {
    const username = getUserSession()?.userInfo?.username
    if (!username) throw Error("No session saved")

    const url = `${BASE_URL}/playlists/${playlistId}/tracks`

    return getCurrentUserToken()
        .then((token) => fetchApiPOST(url, requestDto, token))
        .then((res: any) => {
            console.debug(`addNewTrackToPlaylist response: ${JSON.stringify(res, null, 4)}`)
            return "Track added to playlist"
        })
        .catch((err: Error) => {
            throw Error(`An error happened while adding a track to a playlist: ${err.message}`)
        })
}

export const addExistingTrackToPlaylist = async (trackId: string, playlistId: string): Promise<any> => {
    const username = getUserSession()?.userInfo?.username
    if (!username) throw Error("No session saved")

    const url = `${BASE_URL}/playlists/${playlistId}/tracks/${trackId}`

    return getCurrentUserToken()
        .then((token) => fetchApiPOST(url, null, token))
        .then((res) => {
            console.debug(`addExistingTrackToPlaylist response: ${JSON.stringify(res, null, 4)}`)
            return "Playlist added!"
        })
        .catch((err: Error) => {
            throw Error(`An error happened while adding an existing track to a playlist: ${err.message}`)
        })
}
