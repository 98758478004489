import { useEffect, useState } from "react"

export function useLocalState<T>(key: string, defaultValue: T): [T, (newValue: T) => void] {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key)
        return storedValue === null ? defaultValue : JSON.parse(storedValue)
    })

    useEffect(() => {
        const listener = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === key && e.newValue) {
                setValue(JSON.parse(e.newValue))
            }
        }
        window.addEventListener("storage", listener)

        return () => window.removeEventListener("storage", listener)
    }, [key])

    const setValueInLocalStorage = (newValue: T) => {
        setValue((value: T) => {
            const result = typeof newValue === "function" ? newValue(value) : newValue
            localStorage.setItem(key, JSON.stringify(result))
            return result
        })
    }

    return [value, setValueInLocalStorage]
}
