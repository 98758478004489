export const TAB = String.raw`
----------------------------------------------------------------------
        I BET YOU LOOK GOOD ON THE DANCEFLOOR - ARCTIC MONKEYS
----------------------------------------------------------------------

Band: Arctic Monkeys
Song: I Bet You Look Good On The Dancefloor
Album: Whatever People Say I Am, That's What I'm Not (2006)

Tabbed by: skatersic666
Email: totalrock2003@yahoo.com

Tuning: STANDARD (E A D G B e)

[Intro][00:00]
e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-<2>--<2>--<2>--4---<2>-<2>-<2>-<2>-<2>-<2>---4---<2>-<2>-<2>-<2>--|
D|-<2>--<2>--<2>--4---<2>-<2>-<2>-<2>-<2>-<2>---4---<2>-<2>-<2>-<2>--|
A|-<2>--<2>--<2>--4---<2>-<2>-<2>-<2>-<2>-<2>---4---<2>-<2>-<2>-<2>--|
E|-<2>--<2>--<2>--2---<2>-<2>-<2>-<2>-<2>-<2>---2---<2>-<2>-<2>-<2>--|

e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-<2>-<2>---/16-----------/16-16-16-16-16-16-16-16\14-14-14-14-14---|
D|-<2>-<2>---/16-----------/16-16-16-16-16-16-16-16\14-14-14-14-14---|
A|-<2>-<2>---/16-----------/16-16-16-16-16-16-16-16\14-14-14-14-14---|
E|-<2>-<2>---/14-----------/14-14-14-14-14-14-14-14\12-12-12-12-12---|

[00:08]
e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-14-14-14-14-------------------------------------------9-9-9-9-11--|
D|-14-14-14-14-----------------------9-9-9-9-11-11-11-11-------------|
A|-14-14-14-14---9-9-9-9-12-12-12-12---------------------------------|
E|-12-12-12-12-------------------------------------------------------|

e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-11-11-11-14-14-14-14-16-16-16-16-16b-16b-16b-16b-16b-16b-16b-16b--|
D|-------------------------------------------------------------------|
A|-------------------------------------------------------------------|
E|-------------------------------------------------------------------|

[00:16]
e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|16-14----------14---16-14-----------14---16b(18)=r16====-16--16-16-|
D|------16-14-16------------16-14-16---------------------------------|
A|-------------------------------------------------------------------|
E|-------------------------------------------------------------------|

e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-16-14--------------14---------------------------------------------|
D|---------16---14-16----16--16--16-16-16-14-----------14------------|
A|--------------------------------------------16-14-16----16---------|
E|-------------------------------------------------------------------|

e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-------------------------------------------------------------------|
D|-------------------------------------------------------------------|
A|-16--16-16-14--12----14-12-------------12--14-12---------12--------|
E|----------------------------14-12-14-----------14-12-14------------|

e|-------------------------------------------------------------------|
B|-------------------------------------------------------------------|
G|-------------------------------------------------------------------|
D|-------------------------------------------------------------------|
A|14b15-15-15-15r14\-------------------------------------------------|
E|-------------------------------------------------------------------|

[Verse 1] [00:28]
e|---------------------------------|----------------------------------|
B|---------------------------------|----------------------------------|
G|---------------------------------|----------------------------------|
D|-11--11---9--9-9-x---7--7----4-x-|-11--11---9--9-9-x---7--7----4-x--|
A|-11--11---9--9-9-x---7--7----4-x-|-11--11---9--9-9-x---7--7----4-x--|
E|-9---9----7--7-7-x---5--5----2-x-|-9---9----7--7-7-x---5--5----2-x--|

[Chorus 1][00:36] GUITARS ONE AND TWO (Distortion)
   I bet that you look good on the dancefloor I don't if your looking for
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|2-2-2-2--2-2-2-2--2-2-2-2--2-2-2-2/5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|

  romance  or...  I  don't  know  what  your  looking for_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/14-14-14-14------|

e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
A|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
E|-14-14-14-14--14-14-14-14--14-14-14-14-------------------------------|

 I said I bet tht u look good on the dancefloor  dancing  to  electro-
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|2-2-2-2--2-2-2-2--2-2-2-2--2-2-2-2/5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|

  pop       like     a       robot         from    1984_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/9--9--9--9-------|
 
    ____          from           1984!
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-11-11-11-11--11-11-11-11--11-11-11-11-------------------------------|
A|-11-11-11-11--11-11-11-11--11-11-11-11-------------------------------|
E|-9--9--9--9---9--9--9--9---9--9--9--9--------------------------------|
 
 
 [Solo][01:00]
 
  N.C
  GUITAR ONE (Distortion)
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|---------------------------------------------------------------------|
A|-14--12------14-14-14--14-14-14---12-----------------------14-12---12|
E|------------------------------------14--14-14--14-14-12--14------14--|
|C#5   B5        A5     F#5  C#5  B5         A5    F#5  C#5   B5
|GUITAR TWO (Clean Tone)
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-11-11-9--9-9-x--7--7--4-x--11-11-9--9-9-x--7--7--4-x--11-11-9--9-9-x|
A|-11-11-9--9-9-x--7--7--4-x--11-11-9--9-9-x--7--7--4-x--11-11-9--9-9-x|
E|-9--9--7--7-7-x--5--5--2-x--9--9--7--7-7-x--5--5--2-x--9--9--7--7-7-x|
 
  N.C
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------14-----14--16b17-17-17 17r16\-------------------|
D|-------14-16--14-16------16------------------------------------------|
A|-14/16---------------------------------------------------------------|
E|---------------------------------------------------------------------|
| A5    F#5   C#5              B5        A5    F#5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-7--7--4-x---11----------11---9--9-9-x--7--7--4-x--------------------|
A|-7--7--4-x---11----------11---9--9-9-x--7--7--4-x--------------------|
E|-5--5--2-x---9-----------9----7--7-7-x--5--5--2-x--------------------|
 
 
[Verse 2] GUITAR TWO (Clean Tone)
 
  C#5      B5         A5      F#5   C#5      B5        A5       F#5
   I wish you would stop ignoring me cause its sending me to dispare
   Without a sound yeah ur calling me and i dont think its very fair
    your shoulders are frozen       oh but you are an explosion
    your name isnt Rio.Sound-alikes And the fuse might result.Bang go!
e|---------------------------------|----------------------------------|
B|---------------------------------|----------------------------------|
G|---------------------------------|----------------------------------|
D|-11--11---9--9-9-x---7--7----4-x-|-11--11---9--9-9-x---7--7----4-x--|
A|-11--11---9--9-9-x---7--7----4-x-|-11--11---9--9-9-x---7--7----4-x--|
E|-9---9----7--7-7-x---5--5----2-x-|-9---9----7--7-7-x---5--5----2-x--|
 
 
[Chorus 2][02:00] GUITARS ONE AND TWO (Distortion)
 
  F#5                                 A5
   I bet that you look good on the dancefloor I don't if your looking for
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|2-2-2-2--2-2-2-2--2-2-2-2--2-2-2-2/5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|
 
 E5                                                 F#5
  romance  or...  I  don't  know  what  your  looking for_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/14-14-14-14------|
 
  ___________
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
A|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
E|-14-14-14-14--14-14-14-14--14-14-14-14-------------------------------|
 
  F#5                                 A5
 I said I bet tht u look good on the dancefloor  dancing  to  electro-
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|2-2-2-2--2-2-2-2--2-2-2-2--2-2-2-2/5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|
 
  E5                                                 C#5
  pop       like     a       robot         from    1984_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/9--9--9--9-------|
 
                                       A5
   ____          from              1984!             No there aint
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-11-11-11-11--11-11-11-11--11-11-11-11\7-7-7-7--7-7-7-7--7-7-7-7-----|
A|-11-11-11-11--11-11-11-11--11-11-11-11\7-7-7-7--7-7-7-7--7-7-7-7-----|
E|-9--9--9--9---9--9--9--9---9--9--9--9-\5-5-5-5--5-5-5-5--5-5-5-5-----|
 
           E5                               F#5
 no love no  Montagues       or         Capulets
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------9-9-9-9--9-9-9-9--9-9-9-9--9-9-9-9/11-11-11-11--11-11-11-11-|
D|-7-7-7-7-9-9-9-9--9-9-9-9--9-9-9-9--9-9-9-9/11-11-11-11--11-11-11-11-|
A|-7-7-7-7-7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7/9--9--9--9---9--9--9--9--|
E|-5-5-5-5-------------------------------------------------------------|
 
            just        bang-ing       tunes    'n'     DJ
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|-11-11-11-11--11-11-11-11--11-11-11-11--11-11-11-11--11-11-11-11-----|
D|-11-11-11-11--11-11-11-11--11-11-11-11--11-11-11-11--11-11-11-11-----|
A|-9--9--9--9---9--9--9--9---9--9--9--9---9--9--9--9---9--9--9--9------|
E|---------------------------------------------------------------------|
 
              sets  and    dirty      dance    floors  and  dreams
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|11-11-11-11------------------------------------9-9-9-9--9-9-9-9------|
D|11-11-11-11-7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7-9-9-9-9--9-9-9-9------|
A|9--9--9--9--7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7-7-7-7-7--7-7-7-7------|
E|------------5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5-----------------------|
 
   of   naughtiness
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|-9-9-9-9--9-9-9-9----------------------------------------------------|
D|-9-9-9-9--9-9-9-9----------------------------------------------------|
A|-7-7-7-7--7-7-7-7----------------------------------------------------|
E|---------------------------------------------------------------------|
 
 
 [Bridge]
 
  GUITAR ONE (Distortion)
    F#5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|---4-------4-------4-------4-------4-------4-------4-------4-------4-|
A|---4-------4-------4-------4-------4-------4-------4-------4-------4-|
E|-0-2-----0-2-----0-2-----0-2-----0-2-----0-2-----0-2-----0-2-----0-2-|
|   N.C
|GUITAR TWO (Distortion)
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---<2>-<2>--<2>-<2>-<2>-<2>--<2>-<2>-<2>--<2>---<2>---<2>---<2>-<2>--|
D|---<2>-<2>--<2>-<2>-<2>-<2>--<2>-<2>-<2>--<2>---<2>---<2>---<2>-<2>--|
A|---<2>-<2>--<2>-<2>-<2>-<2>--<2>-<2>-<2>--<2>---<2>---<2>---<2>-<2>--|
E|---<2>-<2>--<2>-<2>-<2>-<2>--<2>-<2>-<2>--<2>---<2>---<2>---<2>-<2>--|
 
    F#5                                 F#5                       E5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|---4-----4-----4-----4------4------4--/16-16-16-16--16-16-16-16\14-14|
A|---4-----4-----4-----4------4------4--/16-16-16-16--16-16-16-16\14-14|
E|-0-2---0-2---0-2---0-2----0-2----0-2--/14-14-14-14--14-14-14-14\12-12|
| N.C                                   F#5                      E5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|-<2>-<2>--<2>-<2>--<2>-<2>---<2>-<2>---------------------------------|
D|-<2>-<2>--<2>-<2>--<2>-<2>---<2>-<2>--/16-16-16-16--16-16-16-16\14-14|
A|-<2>-<2>--<2>-<2>--<2>-<2>---<2>-<2>--/16-16-16-16--16-16-16-16\14-14|
E|-<2>-<2>--<2>-<2>--<2>-<2>---<2>-<2>--/14-14-14-14--14-14-14-14\12-12|
 
                    F#5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-14-14--14-14-14-14/16-----------------------------------------------|
A|-14-14--14-14-14-14/16-----------------------------------------------|
E|-12-12--12-12-12-12/14-----------------------------------------------|
|                   F#5
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-14-14--14-14-14-14/16-----------------------------------------------|
A|-14-14--14-14-14-14/16-----------------------------------------------|
E|-12-12--12-12-12-12/14-----------------------------------------------|
 
 
 [Chorus 3] GUITARS ONE AND TWO (Distortion)
 
                                    A5
 Well I bet that you look good on the dancefloor I don't if your looking for
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-----------------------------------7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|-----------------------------------7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|-----------------------------------5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|
 
  E5                                                 F#5
  romance  or...  I  don't  know  what  your  looking for_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/16-16-16-16------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/14-14-14-14------|
 
  ___________
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
A|-16-16-16-16--16-16-16-16--16-16-16-16-------------------------------|
E|-14-14-14-14--14-14-14-14--14-14-14-14-------------------------------|
 
  F#5                                 A5
 I said I bet tht u look good on the dancefloor  dancing  to  electro-
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
A|4-4-4-4--4-4-4-4--4-4-4-4--4-4-4-4/7-7-7-7--7-7-7-7--7-7-7-7--7-7-7-7|
E|2-2-2-2--2-2-2-2--2-2-2-2--2-2-2-2/5-5-5-5--5-5-5-5--5-5-5-5--5-5-5-5|
 
 E5                                                 C#5
  pop       like     a       robot         from    1984_______
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
A|/14-14-14-14--14-14-14-14--14-14-14-14--14-14-14-14/11-11-11-11------|
E|/12-12-12-12--12-12-12-12--12-12-12-12--12-12-12-12/9--9--9--9-------|
                                         F#5
   ____  I said     from        1 9  8  4!_____
e|---------------------------------------------------------------------|
B|---------------------------------------------------------------------|
G|---------------------------------------------------------------------|
D|-11-11-11-11--11-11-11-11--11-11-11-11--4----------------------------|
A|-11-11-11-11--11-11-11-11--11-11-11-11--4----------------------------|
E|-9--9--9--9---9--9--9--9---9--9--9--9---2----------------------------|
 
 
IF YOU LIKE THIS TAB PLEASE RATE IT
 
THANK YOU.
 
**********************************************
| /  slide up
| \  slide down
| h  hammer-on
| p  pull-off
| ~  vibrato
| +  harmonic
| x  Mute note
 P.M Palm Mute
**********************************************
X
`
