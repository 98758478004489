import { Button, Grid, Paper, styled, TextField, Typography } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import ReactPlayer from "react-player"
import { trimTrackName } from "../base/utils"
import { DEFAULT_SONG_THUMBNAIL_URL, DRAWER_SIZE } from "../common/constants"
import { TrackInfo } from "../common/dto"
import { processTabSearchString } from "../common/utils"
import { CreateTrackDto } from "../integrations/tabuation/dto"
import { createPlaylist } from "../integrations/tabuation/playlists"
import { YoutubePlaylist, YoutubePlaylistItemsResponse, YoutubeVideo } from "../integrations/youtube/dto"
import {
    getPlaylist as getYoutubePlaylist,
    getPlaylistItems as getYoutubePlaylistItems,
} from "../integrations/youtube/youtubeProvider"
import YoutubeTracklist from "./YoutubeTracklist"

const defaultPlaylistId = "OLAK5uy_kCCDmao716AjlsYiNv-M_GaWCc9kWO3y4"
const GUITAR_TABS_URL = "https://www.ultimate-guitar.com/search.php?search_type=title"

const PlaylistHeaderButtons = styled("div")(({ theme }) => ({
    "& > *": {
        margin: theme.spacing(1),
    },
}))

interface State {
    playlist?: YoutubePlaylist
    playlistItems?: YoutubePlaylistItemsResponse
}

export default function YoutubePlaylistPage(): JSX.Element {
    const [state, setState] = useState<State>({})
    const { playlist, playlistItems } = state
    const name = playlist?.snippet?.title ? playlist.snippet.title : "Title not given"
    const owner = playlist?.snippet?.channelTitle ? playlist.snippet.channelTitle : "No owner given"

    const tracks: TrackInfo[] = playlistItems
        ? playlistItems.items.map((video) => {
              const thumbnailUrl = video.snippet.thumbnails.standard
                  ? video.snippet.thumbnails.standard.url
                  : DEFAULT_SONG_THUMBNAIL_URL

              return {
                  id: video.contentDetails.videoId,
                  createdAt: new Date(1995, 11, 17),
                  updatedAt: new Date(1995, 11, 17),
                  ownerUsername: video.snippet.channelTitle,
                  title: video.snippet.title,
                  artists: video.snippet.channelTitle,
                  youtubeLink: video.contentDetails.videoId,
                  pictureUrl: thumbnailUrl,
                  tabs: [],
              }
          })
        : []

    const [selectedTrackIndex, setSelectedTrackIndex] = useState<number | undefined>()
    const selectedTrack: TrackInfo | undefined = selectedTrackIndex ? tracks[selectedTrackIndex] : undefined

    const [mocked, setMocked] = React.useState<boolean>(false)
    const handleMockedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMocked(event.target.checked)
    }

    const [playlistId, setPlaylistId] = React.useState<string>(defaultPlaylistId)
    const handlePlaylistIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setPlaylistId(event.target.value)
    }

    const handlePlaylistSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        getPlaylist(playlistId, mocked)
    }

    const { enqueueSnackbar } = useSnackbar()
    return (
        <Grid
            container
            style={{
                flexGrow: 1,
                position: "fixed",
                height: "90%",
                width: "100%",
                paddingLeft: `${DRAWER_SIZE}px`,
                paddingRight: `${DRAWER_SIZE}px`,
            }}
        >
            <Grid container direction="column" xs={4} style={{ padding: "8px" }}>
                <form noValidate autoComplete="off" onSubmit={handlePlaylistSubmit} style={{ padding: "8px" }}>
                    <TextField
                        id="standard-basic"
                        label="youtube playlist id"
                        value={playlistId}
                        onChange={handlePlaylistIdChange}
                        fullWidth={true}
                    />
                    <Grid container direction="row">
                        {/** 
                        <FormControlLabel
                            control={<Checkbox checked={mocked} onChange={handleMockedChange} />}
                            label="mocked"
                        />
                        **/}
                        <PlaylistHeaderButtons>
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                            {playlist && playlistItems && (
                                <Button variant="contained" onClick={() => addPlaylist(playlist, playlistItems.items)}>
                                    Add
                                </Button>
                            )}
                        </PlaylistHeaderButtons>
                    </Grid>
                </form>
                <YoutubeTracklist
                    name={name}
                    owner={owner}
                    tracks={tracks}
                    height="67vh"
                    handleClick={setSelectedTrackIndex}
                />
            </Grid>
            <Grid container direction="column" xs={8} style={{ width: "100%", alignItems: "center", padding: "8px" }}>
                <Paper style={{ width: "100%", height: "60vh", textAlign: "center" }}>
                    {selectedTrack !== undefined ? (
                        <div>
                            <ReactPlayer
                                url={`https://www.youtube.com/watch?v=${selectedTrack.id}`}
                                controls={true}
                                width="100%"
                                height="60vh"
                                // height="100vh"
                            />
                        </div>
                    ) : (
                        <h2>Youtube/Spotify Player</h2>
                    )}
                </Paper>
                <Paper style={{ width: "100%", padding: "8px", marginTop: "8px" }}>
                    {selectedTrack !== undefined ? (
                        <a
                            href={`${GUITAR_TABS_URL}&value=${processTabSearchString(selectedTrack.title)}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Search tabs for this song
                        </a>
                    ) : (
                        <Typography>Choose a track</Typography>
                    )}
                </Paper>
            </Grid>
        </Grid>
    )

    function getPlaylist(playlistId: string, mocked: boolean) {
        let playlistInfo: any

        getYoutubePlaylist(playlistId, mocked)
            .then((playlist) => {
                playlistInfo = playlist
            })
            .then(() => getYoutubePlaylistItems(playlistId, mocked))
            .then((res) => {
                setState({
                    playlist: playlistInfo,
                    playlistItems: res,
                })
            })
            .catch((err: AxiosError) => {
                enqueueSnackbar(`An error happened while fetching the playlist: ${err.message}`, { variant: "error" })
            })
    }

    function addPlaylist(playlist: YoutubePlaylist, videos: YoutubeVideo[]) {
        const tracksPayload: CreateTrackDto[] = videos.map((video) => {
            const artist = video.snippet.channelTitle

            return {
                title: trimTrackName(video.snippet.title, artist),
                artists: artist,
                youtubeLink: video.contentDetails.videoId,
            }
        })

        const thumbnailUrl = playlist.snippet.thumbnails.standard
            ? playlist.snippet.thumbnails.standard.url
            : DEFAULT_SONG_THUMBNAIL_URL

        createPlaylist({
            name: playlist.snippet.title,
            public: true,
            description: playlist.snippet.description,
            pictureUrl: thumbnailUrl,
            tracks: tracksPayload,
        })
            .then(() => enqueueSnackbar("Playlist added!"))
            .catch((err) =>
                enqueueSnackbar(`An error happened while adding the playlist: ${err.message}`, { variant: "error" })
            )
    }
}
