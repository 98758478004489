import { FormControlLabel, Switch } from "@mui/material"
import React from "react"

export const SettingsSwitch: React.FC<{
    label: string
    name: string
    checked: boolean
    disabled: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}> = ({ label, name, checked, disabled, handleChange }) => {
    return (
        <FormControlLabel
            value="end"
            label={label}
            control={<Switch name={name} checked={checked} disabled={disabled} onChange={handleChange} />}
            labelPlacement="end"
        />
    )
}
