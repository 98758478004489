import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"
import { useSnack } from "../base/Snackbar"
import { deletePlaylist } from "../integrations/tabuation/playlists"

export const DeletePlaylistDialog: React.FC<{
    open: boolean
    playlistId: string
    handleClose: () => void
    handleSuccess: () => void
}> = ({ open, playlistId, handleClose, handleSuccess }) => {
    const { snackSuccess, snackError } = useSnack()
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete Playlist</DialogTitle>
            <Typography>This should be an X</Typography>
            <DialogContent>
                <Typography>Are you sure you want to delete this playlist?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
                <Button type="submit" variant="contained" onClick={handleSubmit} sx={{ backgroundColor: "red" }}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )

    function handleSubmit() {
        deletePlaylist(playlistId)
            .then(() => {
                snackSuccess("Playlist deleted!")
                handleSuccess()
                handleClose()
            })
            .catch((err) => snackError(`Unknown error happened: ${err}`))
    }
}
