import AddIcon from "@mui/icons-material/Add"
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    TextField,
} from "@mui/material"
import React, { useState } from "react"
import { useSnack } from "../base/Snackbar"
import { DEFAULT_SONG_THUMBNAIL_URL } from "../common/constants"
import { PlaylistInfo } from "../common/dto"
import { CreateTrackDto } from "../integrations/tabuation/dto"
import { createPlaylist } from "../integrations/tabuation/playlists"
import { addNewTrackToPlaylist } from "../integrations/tabuation/tracks"
import { getBestThumbnailOrDefault, YoutubeSearchResult } from "../integrations/youtube/dto"

interface Props {
    anchorEl: HTMLElement | null
    handleClose: () => void
    video: YoutubeSearchResult
    playlists: PlaylistInfo[]
    updatePlaylists: () => void
}

export function YoutubeVideoOptions(props: Props): JSX.Element {
    const { anchorEl, handleClose, video, playlists, updatePlaylists } = props

    const [choosePlaylistOpen, setChoosePlaylistOpen] = React.useState<boolean>(false)

    const { snackSuccess, snackError } = useSnack()
    return (
        <div>
            <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={handleClose}>
                <MenuItem onClick={() => setChoosePlaylistOpen(true)}>Add to a Playlist...</MenuItem>
            </Menu>
            <ChoosePlaylistModal open={choosePlaylistOpen} setOpen={setChoosePlaylistOpen} />
        </div>
    )

    function handleError(error: Error) {
        snackError(error.message)
    }

    function ChoosePlaylistModal(props: { open: boolean; setOpen: (open: boolean) => void }): JSX.Element {
        const { open, setOpen } = props

        const [isEdit, setIsEdit] = useState(false)

        return (
            <Dialog id="choose-playlist-modal" open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add this track to:</DialogTitle>
                <DialogContent>
                    <MenuList>
                        {playlists.map((playlist, index) => {
                            return (
                                <MenuItem key={index} onClick={() => addToPlaylist(index, playlist.name, playlist.id)}>
                                    {playlist.name}
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </DialogContent>
                <NewPlaylistAction
                    isEdit={isEdit}
                    setIsEdit={(isEdit) => setIsEdit(isEdit)}
                    createPlaylist={(name) => createPlaylist(playlists.length, name, "New playlist id")}
                />
            </Dialog>
        )

        async function addToPlaylist(index: number, name: string, id: string) {
            snackSuccess(`Added new track to playlist ${index}: ${name} -> ${id}`)
            addNewToPlaylist(id)
            setOpen(false)
        }

        async function createPlaylist(index: number, name: string, id: string) {
            snackSuccess(`Created playlist playlist ${index}: ${name} -> ${id}`)
            createPlaylistWithTrack(name)
            setOpen(false)
        }
    }

    function addNewToPlaylist(playlistId: string) {
        const thumbnailUrl = getBestThumbnailOrDefault(video.snippet.thumbnails, undefined)

        if (!video.id.videoId) {
            throw Error("Video does not have a video id... :thinking:")
        }

        const requestDto: CreateTrackDto = {
            title: video.snippet.title,
            artists: video.snippet.channelTitle,
            youtubeLink: video.id.videoId,

            thumbnailUrl: thumbnailUrl,
        }

        addNewTrackToPlaylist(playlistId, requestDto)
            .then(() => snackSuccess(`Added track to playlist ${playlistId} successfully!`))
            .catch(handleError)
    }

    function createPlaylistWithTrack(name: string) {
        const createTrackDto: CreateTrackDto = {
            title: video.snippet.title,
            artists: video.snippet.channelTitle,
            youtubeLink: video.id.videoId,
        }

        const thumbnailUrl = getBestThumbnailOrDefault(video.snippet.thumbnails, DEFAULT_SONG_THUMBNAIL_URL)

        createPlaylist({
            name: name,
            public: false,
            description: "No description available",
            pictureUrl: thumbnailUrl,
            tracks: [createTrackDto],
        })
            .then(() => snackSuccess("Playlist created!"))
            .then(() => updatePlaylists())
            .catch((err) => snackError(`An error happened while creating the playlist: ${err.message}`))
    }
}

const NewPlaylistAction: React.FC<{
    isEdit: boolean
    setIsEdit: (isEdit: boolean) => void
    createPlaylist: (name: string) => void
}> = ({ isEdit, setIsEdit, createPlaylist }) => {
    const [name, setName] = useState("")

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)
    }

    return (
        <React.Fragment>
            <Box style={{ padding: "8px" }}>
                {!isEdit ? (
                    <ListItem
                        button
                        onClick={() => {
                            setName("")
                            setIsEdit(true)
                        }}
                    >
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={"New playlist..."} />
                    </ListItem>
                ) : (
                    <Grid container>
                        <form>
                            <TextField label="Playlist Name" onChange={handleChange}></TextField>
                            <Button onClick={() => createPlaylist(name)}>Create</Button>
                        </form>
                    </Grid>
                )}
            </Box>
        </React.Fragment>
    )
}
