import { Alert, AlertColor, Button, CircularProgress, Grid, Modal } from "@mui/material"
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { BASE_URL } from "../common/constants"
import { LoginDialog } from "./LoginDialog"
import { getUserSession } from "./session"

interface State {
    loading: boolean
    message: string
    severity: AlertColor
}

const initialState: State = { loading: true, message: "loading...", severity: "info" }

const ONLINE_MESSAGE = "Server status: Online"
const OFFLINE_MESSAGE = "Server status: Offline"

export default function HomePage(): JSX.Element {
    const [state, setState] = React.useState(initialState)
    const { loading, message, severity } = state

    const [openLogin, setOpenLogin] = React.useState(false)

    const checkApiHealth = () => {
        fetch(`${BASE_URL}/api/health`)
            .then((res: Response) =>
                setState({
                    loading: false,
                    message: res.ok ? ONLINE_MESSAGE : message,
                    severity: res.ok ? "success" : "error",
                })
            )
            .catch((err: Error) => {
                setState({ loading: false, message: `${OFFLINE_MESSAGE}: ${err.message}`, severity: "error" })
            })
    }

    useEffect(() => {
        checkApiHealth()
    }, [])

    const loggedIn = getUserSession() != undefined
    return (
        <Grid container direction="column" style={{ padding: "16px" }}>
            <Grid style={{ textAlign: "center" }}>
                <div style={{ height: "10vh" }} />
                <h1>T A B U L A T I O N</h1>
                <p>This is an app to organize and share music/tabs</p>

                <div style={{ height: "10vh" }} />
                <Grid container item justifyContent="center">
                    {loading ? (
                        <>
                            <h3>Api Health: </h3>
                            <CircularProgress />
                        </>
                    ) : (
                        <Alert severity={severity}>{message}</Alert>
                    )}
                </Grid>

                {!loggedIn && (
                    <div>
                        <div style={{ height: "10vh" }} />
                        <div style={{ textAlign: "center" }}>
                            <Button type="button" onClick={() => setOpenLogin(true)}>
                                LOG IN
                            </Button>
                            <span>to save songs and tabs</span>
                        </div>
                        <Modal open={openLogin} onClose={() => setOpenLogin(false)}>
                            <LoginDialog
                                handleClose={() => {
                                    setOpenLogin(false)
                                    window.location.reload()
                                }}
                            />
                        </Modal>
                    </div>
                )}

                <div style={{ height: "10vh" }} />
                <NavLink to="/users">Search users</NavLink>
                <br />
                <NavLink to="/search/youtube">Search Youtube for songs/playlists</NavLink>

                <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
                    <p>{`In environment: '${process.env.NODE_ENV}'`}</p>
                </div>
            </Grid>
        </Grid>
    )
}
