import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"

export const DeleteTabModal: React.FC<{
    open: boolean
    setOpen: (open: boolean) => void
    deleteTab: () => void
}> = ({ open, setOpen, deleteTab }) => {
    const handleClose = () => {
        setOpen(false)
    }

    const handleAction = () => {
        deleteTab()
        handleClose()
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Delete tab"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure that you want to delete this tab? this action is permanent!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        Go back
                    </Button>
                    <Button variant="contained" onClick={handleAction} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
