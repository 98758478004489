import { MenuOutlined, MoreVert, MusicNote, QueueMusic } from "@mui/icons-material"
import { Avatar, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material"
import React from "react"
import { TrackInfo } from "../common/dto"

export const defaultAvatar = "https://static.thenounproject.com/png/55431-200.png"

interface Props {
    track: TrackInfo
    selected: boolean
    handleClick: () => void
    openMenu?: (event: React.MouseEvent<HTMLElement>) => void
}

export function YoutubeTracklistItem(props: Props): JSX.Element {
    const { track, selected, handleClick, openMenu } = props

    const avatarUrl = track.pictureUrl ? track.pictureUrl : defaultAvatar

    const hasAudio: boolean = track.youtubeLink && track.spotifyLink && track.soundcloudLink ? true : false
    const hasTabs = track.tabs && track.tabs.length > 0

    const audioIconOpacity = hasAudio ? 1 : 0
    const tabsIconOpacity = hasTabs ? 1 : 0

    return (
        <ListItem button selected={selected} onClick={handleClick}>
            <Avatar variant="square" src={avatarUrl} style={{ height: "46px", width: "46px", marginRight: "16px" }} />
            <ListItemText
                primary={track.title}
                secondary={track.artists}
                style={{ overflowX: "hidden", overflowY: "auto", wordWrap: "break-word" }}
            />
            <ListItemSecondaryAction>
                <MusicNote style={{ opacity: audioIconOpacity }} />
                <QueueMusic style={{ opacity: tabsIconOpacity }} />
                {openMenu && (
                    <IconButton onClick={(event: React.MouseEvent<HTMLElement>) => openMenu(event)}>
                        <MenuOutlined />
                    </IconButton>
                )}
                <MoreVert />
            </ListItemSecondaryAction>
        </ListItem>
    )
}
