import { getCurrentUserToken, getUserSession } from "../../base/session"
import { BASE_URL } from "../../common/constants"
import { TrackInfo } from "../../common/dto"
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "./../../common/constants"
import { fetchApiGET, fetchApiPATCH, fetchApiPOST } from "./apiFetcher"
import { ApiResponse, CreateTrackDto, UpdateTabDto } from "./dto"

export const getUserTracks = async (
    username: string,
    page: number = DEFAULT_PAGE_NUM,
    size: number = DEFAULT_PAGE_SIZE
): Promise<TrackInfo[]> => {
    const url = `${BASE_URL}/users/${username}/tracks?page=${page}&size=${size}`

    return getCurrentUserToken()
        .then((token) => fetchApiGET(url, token))
        .then((res) => {
            return res.data._embedded?.trackList ? res.data._embedded?.trackList : []
        })
        .catch((err: Error) => {
            throw Error(`An error happened while fetching the user's tracks: ${err.message}`)
        })
}

export const updateTrack = async (trackId: string, requestDto: UpdateTabDto): Promise<TrackInfo> => {
    const username = getUserSession()?.userInfo?.username
    if (!username) throw Error("No username provided for 'add track to collection'")

    const token = await getCurrentUserToken()
    if (!token) throw Error("You need to be signed in to 'add track to collection'")

    const url = `${BASE_URL}/tracks/${trackId}`

    return fetchApiPATCH(url, requestDto, token)
        .then((res: ApiResponse) => res.data as TrackInfo)
        .catch((err: Error) => {
            throw Error(`An error happened while updating track: ${err.message}`)
        })
}

export const addTrackToCollection = async (requestDto: CreateTrackDto): Promise<TrackInfo> => {
    const username = getUserSession()?.userInfo?.username
    if (!username) throw Error("No username provided for 'add track to collection'")

    const token = await getCurrentUserToken()
    if (!token) throw Error("You need to be signed in to 'add track to collection'")

    const url = `${BASE_URL}/users/${username}/tracks`

    return fetchApiPOST(url, requestDto, token)
        .then((res: ApiResponse) => res.data as TrackInfo)
        .catch((err: Error) => {
            throw Error(`An error happened while adding a track to the collection: ${err.message}`)
        })
}

export const addNewTrackToPlaylist = async (playlistId: string, requestDto: CreateTrackDto): Promise<TrackInfo> => {
    const token = await getCurrentUserToken()
    if (!token) throw Error("You need to be signed in to 'add track to playlist'")

    const url = `${BASE_URL}/playlists/${playlistId}/tracks`

    return fetchApiPOST(url, requestDto, token)
        .then((res: ApiResponse) => res.data as TrackInfo)
        .catch((err: Error) => {
            throw Error(`An error happened while adding a new track to the playlist ${playlistId}: ${err.message}`)
        })
}
