import { Grid, List, Paper, styled } from "@mui/material"
import React, { useState } from "react"
import { PlaylistInfo, TrackInfo } from "../../../common/dto"
import { TrackListItem } from "./TrackListItem"

const TracklistContainer = styled(Grid)(() => ({
    width: "100%",
    flexGrow: 1,
    flexDirection: "column",
    overflow: "auto",
}))

export const Tracklist: React.FC<{
    tracks: TrackInfo[]
    handleClick: (index: number) => void
    height?: number | string
    canEdit: boolean
    handleUpdate?: (updated: TrackInfo) => void
    playlists: PlaylistInfo[] | undefined
    updatePlaylists: () => void
}> = ({ tracks, handleClick, height, canEdit, handleUpdate, playlists, updatePlaylists }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const handleListItemClick = (index: number) => {
        setSelectedIndex(index)
        handleClick(index)
    }

    return (
        <React.Fragment>
            <Paper>
                <TracklistContainer item style={{ height: height }}>
                    <List component="nav" dense={true} style={{ width: "100%", padding: "0px" }}>
                        {tracks &&
                            tracks.map((track: TrackInfo, index: number) => {
                                return (
                                    <TrackListItem
                                        key={`tracklist_${index}`}
                                        selected={index === selectedIndex}
                                        track={track}
                                        handleClick={() => handleListItemClick(index)}
                                        canEdit={canEdit}
                                        handleUpdate={handleUpdate}
                                        playlists={playlists}
                                        updatePlaylists={updatePlaylists}
                                    />
                                )
                            })}
                    </List>
                </TracklistContainer>
            </Paper>
        </React.Fragment>
    )
}
