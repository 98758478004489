import React from "react"
import { PlaylistInfo } from "../common/dto"
import { YoutubeSearchResult } from "../integrations/youtube/dto"
import YoutubeVideoCard from "./YoutubeVideoCard"

interface Props {
    videos: YoutubeSearchResult[]
    onVideoClick: (video: YoutubeSearchResult) => void
    playlists?: PlaylistInfo[]
    updatePlaylists?: () => void
}

export default function YoutubeVideoGrid(props: Props): JSX.Element {
    const { videos, onVideoClick } = props

    return (
        <div
            style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
                padding: "8px",
                gridGap: "8px",
                paddingLeft: "16px",
            }}
        >
            {videos.map &&
                videos.map((video, index) => (
                    <div key={index}>
                        <YoutubeVideoCard video={video} onClick={() => onVideoClick(video)} />
                    </div>
                ))}
        </div>
    )
}
